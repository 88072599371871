export const state = () => ({
  user: null,
  authenticated: false
})

export const mutations = {
  setAuthenticated (state, { user }) {
    state.user = !user ? null : user
    state.authenticated = (user && Object.keys(user).includes('email')) === true
  }
}
