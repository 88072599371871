export const state = () => ({
  events: null,
  registrations: null,
  barks: null,
  tests: null
})

export const mutations = {
  setTests (s, tests) {
    s.tests = tests
  },

  setEvents (s, events) {
    s.events = events
  },

  setBarks (s, barks) {
    s.barks = barks
  },

  setRegistrations (s, registrations) {
    s.registrations = registrations
  }
}
