import Vue from 'vue'
import Vuex from 'vuex'

import * as auth from './auth'
import * as storage from './storage'
import * as app from './app'
import * as navigation from './navigation'
import * as rx from './rx'

const mapMutations = (moduleName, moduleObj) => {
  const mutationNames = Object.keys(moduleObj.mutations)
  const newMutations = {}

  mutationNames.forEach((mutationName) => {
    Object.defineProperty(newMutations, `${moduleName}/${mutationName}`, {
      value: moduleObj.mutations[mutationName],
      writable: false,
      enumerable: true
    })
  })

  return {
    state: moduleObj.state,
    mutations: newMutations,
    getters: moduleObj.getters
  }
}

Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,
  modules: {
    auth: mapMutations('auth', auth),
    storage: mapMutations('storage', storage),
    app,
    navigation,
    rx
  }
})
