const _ = require('lodash')

class NoseworkSyncFlow {
  constructor (context) {
    this.context = context
  }

  async init () {
    this.setListeners()
    if (this.context.state().authenticated === true) {
      this.context.state().uploaded = !(await this.hasUpload())
      this.context.state().downloaded = !(await this.mustDownload())

      await this.checkSync()
    } else {
      this.context.state().checkingSync = false
      this.context.state().mustCheckSync = false
    }
    this.context.state().syncInitialized = true
    return true
  }

  setListeners () {
    this.context.on('authenticated', true, () => {
      this.context.state().mustCheckSync = true
    })
    this.context.on('synchronized', true, () => {
      this.context.state().mustUpload = false
      this.context.state().mustDownload = false
      this.context.state().absLastSync = Date.now()
      setTimeout(() => {
        const updatePayload = this.context.state().downloaded ? {
          lastDownload: this.context.state().absLastSync
        } : {
          lastUpload: this.context.state().absLastSync
        }
        this.context.storage().updateAppState(updatePayload)
      }, 500)
    })
    this.context.on('mustCheckSync', true, () => {
      this.context.state().uploaded = false
      this.context.state().downloaded = false
      this.checkSync()
    })
  }

  /**
   * Flow is as following:
   * 1. dontSync when app was closed and restarted recently (last 2 hours)
   * 2. mustUpload when there are payloads pending older than 12 hours
   * 3. mustUpload when the last app state was timer closed/dog evaluated
   * 5. mustDownload when the app is fresh, was last closed longer than 16 hours
   * @returns {Promise<void>}
   */
  async checkSync ({ upload, download } = { upload: true, download: true }) {
    this.context.state().checkingSync = true
    this.context.state().mustCheckSync = false

    const mustUpload = false
    let mustDownload = false

    if (upload) {
      // NOTE: There is no must for upload
      /* mustUpload = await this.mustUpload() */
      /* this.context.state().mustUpload = mustUpload */
    }

    if (download) {
      mustDownload = await this.mustDownload()
      this.context.state().mustDownload = mustDownload
    }

    return mustUpload || mustDownload
  }

  async mustDownload () {
    if (!this.context.storage().collections.event) {
      this.context.state().checkingSync = false
      return true
    }

    const appState = this.context.storage().parseAppState(await this.context.storage().getLastAppState())
    const events = await this.context.storage().collections.event.find().exec()

    const hasEvents = events.length > 0
    this.context.state().hasEvents = hasEvents

    const registrations = await this.context.storage().collections.registration.find().exec()

    const hasRegistrations = registrations.length > 0
    this.context.state().hasRegistrations = hasRegistrations

    if (!hasEvents || !hasRegistrations) {
      this.context.state().checkingSync = false
      return true
    }

    const lastUsed = _.get(appState, 'lastUsed', Date.now() - 365 * 24 * 60 * 60 * 1000)
    const deadHours = (Date.now() - lastUsed) / 1000 / 60 / 60

    if (deadHours <= 6 && hasEvents) {
      this.context.state().checkingSync = false
      this.context.state().dontSync = true
      return false
    }

    this.context.state().checkingSync = false
    return true
  }

  async hasUpload () {
    const changes = await this.context.storage().collections.change.find().exec()
    if (!changes) {
      return false
    }

    return changes.length > 0
  }

  async deadHours () {
    return await new Promise((resolve) => {
      if (this.context.state().fresh) {
        resolve(999999999)
      } else {
        resolve(1)
      }
    })
  }
}

export default ctx => new NoseworkSyncFlow(ctx)
