const heartbeats = require('heartbeats')
const Ee = require('eventemitter2')

const VueHeartbeats = {
  install (Vue) {
    const emitter = new Ee()
    const heart = heartbeats.createHeart(1000, 'heart')

    const now = new Date()
    const delay = (61 - now.getSeconds()) * 1000

    emitter.emit('minute.full', true)
    emitter.emit('minute.half', true)

    setTimeout(() => {
      heart.createEvent(60, () => {
        emitter.emit('minute.full', false)
      })

      heart.createEvent(30, () => {
        emitter.emit('minute.half', false)
      })
    }, delay)

    Vue.prototype.$pulse = emitter
  }
}

export default VueHeartbeats
