// import { LocalNotifications } from '@ionic-native/local-notifications'
// import { BackgroundMode } from '@ionic-native/background-mode'
import { NoseworkPlatform } from './platform'

class NoseworkAndroid extends NoseworkPlatform {
  constructor (context) {
    super(context)
    this.initOperations = [
      // 'enableBgMode',
      'network',
      'battery'
    ]

    // this.backgroundMode = BackgroundMode
    //
    // this.localNotifications = LocalNotifications
    //
    // this.backgroundMode.setDefaults({
    //   silent: true
    // })
    // this.notificationData = {
    //   id: Date.now(),
    //   title: 'Nosework is running',
    //   text: 'Tap this notification to open',
    //   sound: null,
    //   foreground: true,
    //   vibrate: false,
    //   ongoing: true
    // }

    // super.context.state().isMobile = true
  }

  // async enableBgMode () {
  //   return await new Promise((resolve) => {
  //     this.backgroundMode.enable()
  //     this.backgroundMode.on('enable').subscribe(this.onEnableSubscribe.bind(this))
  //     this.backgroundMode.on('disable').subscribe(this.onDisableSubscribe.bind(this))
  //     this.backgroundMode.on('activate').subscribe(this.onActivateSubscribe.bind(this))
  //     this.backgroundMode.on('deactivate').subscribe(this.onDeactivateSubscribe.bind(this))
  //     resolve(true)
  //   })
  // }

  // onEnableSubscribe () {
  //   this.localNotifications.requestPermission()
  //   this.backgroundMode.overrideBackButton()
  //   super.context.state().backgroundable = true
  // }

  // onDisableSubscribe () {
  //   this.context.state().backgroundable = false
  // }

  // onActivateSubscribe () {
  //   this.context.state().backgrounded = true
  //   this.localNotifications.schedule(this.notificationData)
  // }

  // onDeactivateSubscribe () {
  //   this.context.state().backgrounded = false
  //   this.localNotifications.cancel(this.localNotifications.getIds())
  // }
}

export default context => new NoseworkAndroid(context)
