import { Observable } from 'hyperactiv/src/classes'

const raw = new Observable({
  httpOn: false,
  storageOn: false,
  timerOn: false,
  isAuthenticated: false,
  authenticating: false,
  hasDevice: false,
  hasPlatform: false,
  platformInitialized: false,
  hasStorage: false,
  hasVuex: false,
  authReady: false,
  hasUserData: false,
  syncInitialized: false,
  isFresh: false,
  downloading: false,
  uploading: false,
  initializing: false,
  hasPayloads: false,
  canMergePayloads: false,
  fresh: false,
  checkingShouldSync: false,
  mustDownload: false,
  mustUpload: false,
  mustConnect: false,
  absComponent: null,
  absVerified: {},
  isMobile: false,
  batteryLevel: -1,
  absLastSync: null,
  absLastUpload: null,
  absLastDownload: null,
  absId: null,
  deauthenticating: false,
  deauthenticated: false,
  dontSync: false,
  absForceEnergyMode: null,
  absGracefulFinish: null,
  canDeauthenticate: false,
  hasCompetitions: false,
  checkingSync: false,
  mustCheckSync: false,
  absDeauthenticationToken: null,
  hasApp: false
})

const rawImmediate = new Observable({
  initialized: false,
  uploaded: false,
  downloaded: false,
  synchronized: false,
  showSyncWrapper: true,
  synchronizing: false,
  processing: false,
  authenticated: false,
  shouldLogin: false,
  mustSync: false,
  mustCharge: false,
  canTouch: false,
  highEnergy: false,
  midEnergy: false,
  lowEnergy: false,
  closeWhenBackgrounded: true
}, { batch: 1000 })

const shortDelayed = new Observable({
  busy: false,
  ready: false
}, { batch: 1500 })

class NoseworkState {
  constructor () {
    this.hasVuex = false
    this.hasInitiatedVuex = false
    this.pristine = true
    this.context = null
  }
}

NoseworkState.prototype = {

  setContext (context) {
    this.context = context
    this.hasInitiatedVuex = true
  },

  async hasVuex (initiated = false) {
    if (this.hasInitiatedVuex || (this.hasVuex && !initiated)) {
      return true
    } else {
      return await new Promise((resolve) => {
        const checkVuex = () => {
          const has = this.hasVuex
          if (!initiated) {
            return has
          } else {
            return has && this.hasInitiatedVuex
          }
        }
        if (!checkVuex()) {
          resolve(setTimeout(() => checkVuex()), 500)
        } else {
          resolve(true)
        }
      })
    }
  },

  async initVuex () {
    await this.hasVuex()
    try {
      this.context.vuex().commit('setAppState', this.getAllObservables())
    } catch (err) {
      // console.log(err, 'initvuex')
    }
    this.hasInitiatedVuex = true
  },

  shouldPushToVuex (key) {
    const alwaysPush = [
      'ready',
      'busy',
      'hasPayloads',
      'synchronizing',
      'synchronized',
      'hasCompetitions'
    ]
    return key.substr(0, 4) === 'must' ||
        key.substr(0, 5) === 'should' ||
        key.substr(0, 2) === 'is' ||
      key.substr(0, 4) === 'mode' ||
      key.substr(0, 7) === 'battery' ||
      key.substr(0, 3) === 'abs' ||
      alwaysPush.includes(key) ||
    // @todo
    Object.getOwnPropertyNames(fullState).includes(key)
  },

  async updateVuex (payload) {
    await this.hasVuex(true)
    for (const change of payload) {
      if (!this.shouldPushToVuex(change.key)) {
        continue
      }
      try {
        this.context.vuex().commit('setAppStateField', { key: change.key, value: change.value })
        // console.log('vuex ok', change)
      } catch (err) {
        // console.log(err, 'vuex', change)
      }
    }
  },

  async trigger (prop, value) {
    await this.context.trigger(prop, value)
  }
}

raw.computed(() => {
  const initReqs = ['hasDevice', 'hasPlatform', 'platformInitialized', 'hasStorage', 'hasVuex', 'authReady', 'hasUserData']
  const initialized = initReqs.map(i => raw[i]).length === 0

  if (raw.absGracefulFinish) {
    const when = raw.absGracefulFinish
    setTimeout(() => {
      raw.gracefulFinish = when
    }, 100)
  }

  Object.assign(rawImmediate, {
    processing: raw.httpOn || raw.storageOn,
    synchronized: raw.uploaded && raw.downloaded,
    synchronizing: raw.uploading || raw.downloading,
    initialized,
    authenticated: !raw.authenticating && raw.isAuthenticated,
    mustSync: !raw.dontSync && (raw.mustDownload || raw.mustUpload),
    shouldLogin: !raw.authenticating && raw.hasUserData && !rawImmediate.busy && !raw.isAuthenticated,
    mustConnect: raw.isConnected === false && (raw.mustUpload || raw.mustDownload || (!raw.authenticated && !raw.isAuthenticated)),
    canTouch: !rawImmediate.mustSync && raw.isAuthenticated,
    highEnergy: raw.absForceEnergyMode === 'high' || raw.batteryLevel >= 0.5,
    midEnergy: raw.absForceEnergyMode === 'mid' || (raw.batteryLevel >= 0.3 && raw.batteryLevel < 0.5),
    lowEnergy: raw.absForceEnergyMode === 'low' || raw.batteryLevel < 0.3,
    closeWhenBackgrounded: false
  })

  setTimeout(() => {
    Object.assign(shortDelayed, {
      busy: rawImmediate.processing
    })
  }, rawImmediate.processing ? 1 : 500)
})

rawImmediate.computed(() => {
  Object.assign(shortDelayed, {
    busy: rawImmediate.processing,
    shouldSync: true
  })
})

shortDelayed.computed(() => {
  const canMergePayloads = !shortDelayed.busy && raw.isConnected && raw.batteryHigh && shortDelayed.ready && raw.hasPayloads

  setTimeout(() => {
    raw.canMergePayloads = canMergePayloads
  }, !canMergePayloads ? 0 : 1000)

  if (shortDelayed.busy) {
    raw.shouldWait = true
  }

  shortDelayed.absLastUsed = Date.now()
})

const fullState = new Observable(new NoseworkState(), { batch: 0 })

const allowedEvents = [
  'ready',
  'isAuthenticated',
  'isConnected',
  'synchronizing',
  'synchronized',
  'shouldWait',
  'highEnergy',
  'midEnergy',
  'lowEnergy',
  'deauthenticated',
  'deauthenticating',
  'authenticated',
  'canDeauthenticate',
  'mustCheckSync'
]

fullState.onChange((keys, values, old, obj) => {
  NoseworkState.prototype.updateVuex(keys.map(k => ({ key: k, value: values })))

  keys.filter(key => allowedEvents.includes(key)).forEach((key) => {
    NoseworkState.prototype.trigger(key, values)
  })
})

const onChange = (a, b, c, d) => {
  a.forEach((key) => {
    if (key !== 'prototype') {
      fullState[key] = b
    }
  })
}

raw.onChange(onChange)
rawImmediate.onChange((a, b, c, d) => {
  onChange(a, b, c, d)
})
shortDelayed.onChange(onChange)

export default (ctx) => {
  NoseworkState.prototype.setContext(ctx)

  const updateAppState = (ctx, prop, value) => {
    const allowed = [
      'lastUsed',
      'user',
      'lastState',
      'deauthenticating',
      'hasPayloads',
      'appId',
      'verified'
    ]

    let key = prop

    if (key.substr(0, 3) === 'abs') {
      key = prop[3].toLowerCase() + prop.substr(4)
    }

    if (!allowed.includes(key)) {
      return
    }

    const payload = {}

    Object.defineProperty(payload, key, {
      value,
      enumerable: true,
      writable: true
    })
    ctx.storage().updateAppState(payload)
  }

  const proxy = new Proxy(fullState, {
    set (obj, prop, value) {
      if (prop.substr(0, 1) === '_') {
        return true
      }

      if (prop === 'initialized' && value) {
        raw.initializing = false
        setTimeout(() => { shortDelayed.ready = true }, 500)
      }

      if (['downloaded', 'uploaded'].includes(prop)) {
        raw.synchronized = true
        raw.shouldDownload = false
        raw.shouldUpload = false
      }

      // console.log(`Set ${prop} ${value}`)

      if (prop === 'fresh') {
        raw.shouldDownload = value
      }

      if (prop === 'deauthenticated') {
        raw.deauthenticating = !value

        if (value === true) {
          raw.canDeauthenticate = false
        }
      }

      if (prop === 'authenticated') {
        raw.authenticating = !value

        if (value === true) {
          raw.canDeauthenticate = false
          raw.deauthenticated = false
        }
      }

      if (prop === 'deauthenticating' && value === false) {
        raw.deauthenticated = true
      }

      if (prop === 'authenticating' && value === true) {
        raw.authenticated = false
      }

      raw[prop] = value

      if (raw.initialized) {
        updateAppState(ctx, prop, value)
      }

      raw.shouldWait = shortDelayed.busy || raw.timerOn

      return true
    },

    get (obj, prop) {
      if (prop === 'value') {
        return Object.getOwnPropertyNames(obj).map((prop) => {
          if (typeof (obj[prop]) !== 'object' && typeof (obj[prop]) !== 'function' && !!obj[prop]) {
            return Object.defineProperty({}, prop, {
              value: obj[prop],
              enumerable: true,
              writable: false
            })
          }

          return null
        }).filter(i => i)
      } else {
        return obj[prop]
      }
    }
  })

  return proxy
}
