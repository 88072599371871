import { nosework } from './nosework/src'

const _ = require('lodash')

const VueNosework = {
  install (Vue, options) {
    Vue.prototype.$nosework = nosework(options)

    Vue.mixin({
      computed: {
        appState () {
          return this.$store.state.app.appState
        }
      },

      mounted () {
        const path = _.get(this, '$route.path', null)

        if (path !== null) {
          this.$nosework.context.state().absComponent = path
        }
      },

      beforeMount () {
        if (this.$store !== undefined) {
          this.$nosework.context.set('vuex', this.$store)
        }
      },

      methods: {
        debounceCanUpload: _.debounce(function (t, value) {
          console.log('can upload', value)
          t.canUpload = value
        }, 1000),

        debounceCanMerge: _.debounce(function (t, value) {
          console.log('can merge', value)
          t.canMerge = value
        }, 1000),

        debounceMerge: _.debounce(function (t) {
          console.log('debounced merge')
          t.$nosework.context.storage().uploader.mergePayloads()
        }, 5000),

        debounceUpload: _.debounce(function (t) {
          console.log('debounced upload')
          t.$nosework.context.storage().uploader.upload()
        }, 5000),

        debounceRedirect: _.debounce(function (t, to) {
          t.$router.push(to)
        }, 1000),

        redirect (to) {
          if (this.$route.path !== to) {
            this.debounceRedirect(this, to)
          }
        }
      }
    })
  }
}

export default VueNosework
