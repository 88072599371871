import { NoseworkLogger } from './services/logger'
import { NoseworkInit } from './services/init'
import { NoseworkPreflight } from './services/preflight'

import NoseworkBloodTrackingTimer from './services/bloodTrackingTimer'
import NoseworkState from './services/state'
import smoothcomp from './services/smoothcomp'

const _ = require('lodash')
const shortid = require('shortid')

shortid.characters('0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-:')

/**
 * Default options
 */
const defaultOptions = {
  store: {
    container: null
  }
}

/**
 * @param ctx
 * @returns {NoseworkContext}
 * @constructor
 */
const NoseworkContext = function (ctx) {
  Object.assign(this, ctx)
  return this
}

NoseworkContext.prototype = {
  /**
   * Magic getter for Nosework context
   * @todo test!
   * @param name
   * @return {null|*}
   */
  prop (name) {
    if (name in this) {
      const fn = this[name]()
      return fn
    }
    return null
  }
}

/**
 * @module Nosework
 * @param opts
 * @constructor
 */
function Nosework (opts) {
  this.options = _.merge(defaultOptions, opts)

  this.state = NoseworkState(this.context)
  this.logger = new NoseworkLogger(this.context)
  this.preflight = new NoseworkPreflight(this.context)
  this.smoothcomp = smoothcomp(this.context)
  this.bloodTrackingTimer = NoseworkBloodTrackingTimer(this.context)
  this.timestamp = Date.now()

  this.vuex = null
  this.subscribers = []
  this.listeners = []

  this.elapsed = (current, steps, index) => {
    let elapsed = 0

    if (current.state === 'start') {
      return 0
    }

    if (current.state === 'restart') {
      elapsed = steps[index - 1].elapsed
      return elapsed
    }

    const firstStep = _.first(steps)
    const firstTimestamp = firstStep ? firstStep.timestamp : current.timestamp

    elapsed = current.timestamp - firstTimestamp
    const cts = Math.trunc(current.timestamp / 1000)

    const pauses = steps.filter(s => Math.trunc(s.timestamp / 1000) <= cts)

    // Find and remove all pauses
    if (pauses.length > 0) {
      let start = 0

      pauses.forEach((p) => {
        if (p.state === 'pause') {
          start = p.timestamp ?? 0
        } else if (start !== 0) {
          elapsed -= p.timestamp - start
          start = 0
        }
      })
    }

    return elapsed
  }

  this.listen = (...data) => {
    const fn = data[3] || data[2]
    const once = data[0]
    const id = shortid.generate()

    this.listeners.push({
      id,
      prop: data[1],
      fn,
      once
    })

    return id
  }

  this.subscribe = (...data) => {
    const fn = data[3] || data[2]
    const value = data[3] ? data[2] : true
    const once = data[0]
    const id = shortid.generate()

    this.subscribers.push({
      id,
      prop: data[1],
      value,
      fn,
      once
    })

    return id
  }

  this.watch = (...data) => {
    return this.listen(false, ...data)
  }

  this.when = (...data) => {
    return this.listen(true, ...data)
  }

  this.on = (...data) => {
    return this.subscribe(false, ...data)
  }

  this.once = (...data) => {
    return this.subscribe(true, ...data)
  }

  this.ignoredTriggers = []

  this.checkRules = (rules, values, retval = true) => {
    if (!rules) {
      return retval
    }

    return rules.filter((r) => {
      const selected = {}
      const keys = Object.keys(r)

      keys.forEach((k) => {
        const value = values.find(s => s.type === k)
        selected[k] = value ? value.value_text : ''
      })

      const check = Object.keys(selected).filter(s => r[s].includes(selected[s])).length
      return check === keys.length
    }).length > 0
  }

  this.getEvaluations = (evaluation, options) => {
    const groupTypes = ['group']
    const _options = {
      skipDynamic: false,
      onlyValidation: false,
      checkRules: [],
      ...options
    }

    if (!_options.skipDynamic) {
      groupTypes.push('dynamic')
    }

    return _.flatten(evaluation.filter(
      e => this.checkRules(e.rules, _options.checkRules)
    ).map((e) => {
      const groups = this.getEvaluations(e.inputs.filter(
        i => groupTypes.includes(i.type)
      ), _options)

      return [
        ...e.inputs.filter(i => i.type !== 'group').map((i) => {
          if (_options.onlyValidation && i.validate === false) {
            return null
          }

          if (e.type) {
            if (e.type === 'dynamic') {
              return new RegExp(`^${e.scope}_${i.scope}_[0-9]+$`)
            }
          }

          if (i.type === 'chase_select') {
            return new RegExp('best_chase_(paw|hoof)+$')
          }

          return new RegExp(`^${i.scope}$`)
        }),
        ...groups
      ]
    })).filter(e => e)
  }

  this.debouncedAppStateTrigger = _.debounce((t) => {
    const subscribers = t.subscribers.filter(i => i.prop === 'appChange') || []

    for (const subscriber of subscribers) {
      try {
        subscriber.fn(() => t.context.state())
      } catch (err) {
        t.context.logger().error(err)
      }
    }
    return true
  }, 5000)

  this.event = async (prop, value) => {
    let listeners = []

    this.debouncedAppStateTrigger(this)

    if (!this.ignoredTriggers.includes(prop)) {
      listeners = this.listeners.filter(i => i.prop === prop) || []
    } else {
      return true
    }

    const resolved = []

    for (const listener of listeners) {
      await new Promise((resolve) => {
        try {
          listener.fn(value)

          resolve(true)
        } catch (err) {
          this.context.logger().error(err)

          resolve(false)
        }
      })

      resolved.push(listener.id)
    }

    this.listeners = this.listeners.filter((i) => {
      return !(i.prop === prop && _.get(i, 'once', false))
    })

    return true
  }

  this.trigger = async (prop, value) => {
    let subscribers = []

    this.debouncedAppStateTrigger(this)

    if (!this.ignoredTriggers.includes(prop)) {
      subscribers = this.subscribers.filter(i => i.prop === prop && i.value === value) || []
    } else {
      return true
    }

    const resolved = []

    for (const subscriber of subscribers) {
      await new Promise((resolve) => {
        try {
          subscriber.fn()

          resolve(true)
        } catch (err) {
          this.context.logger().error(err)

          resolve(false)
        }
      })

      resolved.push(subscriber.id)
    }

    this.subscribers = this.subscribers.filter((i) => {
      return !(i.prop === prop && _.get(i, 'once', false))
    })

    return true
  }

  this.interval = setInterval(async () => {
    this.timestamp = Date.now()
    await this.event('timestamp', this.timestamp)
  }, 1000)

  this.init()
}

/**
 * @module Nosework
 */
Nosework.prototype = {
  options: {},
  ready: false,
  platform: {},
  storage: null,
  auth: null,
  smoothcomp: null,
  device: null,
  network: null,
  battery: null,
  subscribers: [],

  set (prop, value) {
    this[prop] = value
  },

  get context () {
    const modules = [
      'auth',
      'logger',
      'storage',
      'preflight',
      'platform',
      'smoothcomp',
      'bloodTrackingTimer',
      'state',
      'device',
      'network',
      'battery',
      'vuex'
    ]

    const ctx = {}

    modules.forEach((module) => {
      Object.defineProperty(ctx, module, {
        value: () => this[module],
        writable: false,
        enumerable: true
      })
    })

    Object.defineProperty(ctx, 'options', {
      value: (path, fallback) => {
        return this.getOpt(path, fallback)
      },
      writable: false,
      enumerable: true
    })

    Object.defineProperty(ctx, 'set', {
      value: (prop, value) => {
        this[prop] = value
      },
      writable: false,
      enumerable: true
    })

    Object.defineProperty(ctx, 'on', {
      value: this.on,
      writable: false,
      enumerable: true
    })

    Object.defineProperty(ctx, 'once', {
      value: this.once,
      writable: false,
      enumerable: true
    })

    Object.defineProperty(ctx, 'trigger', {
      value: (prop, value) => this.trigger(prop, value),
      writable: false,
      enumerable: true
    })

    return new NoseworkContext(ctx)
  },

  getOpt (path, fallback = null) {
    return _.get(this.options, path, fallback)
  },

  async init () {
    this.context.logger().info('Nosework init')

    const i = new NoseworkInit(this.context)
    await i.init()

    this.context.logger().info('Nosework init finished')
  }
}

/**
 * @module Nosework
 * @returns {Nosework}
 */
export const nosework = opts => new Nosework(opts)
