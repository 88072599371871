const changeSchema = {
  version: 0,
  title: 'Change schema',
  keyCompression: true,
  type: 'object',
  properties: {
    id: {
      type: 'string',
      primary: true
    },
    entity: {
      type: 'string'
    },
    entityId: {
      type: 'string'
    }
  },
  indexes: ['id', 'entityId'],
  required: ['id', 'entity', 'entityId']
}

const registrationSchema = {
  version: 3,
  title: 'Registration schema',
  keyCompression: true,
  type: 'object',
  properties: {
    id: {
      type: 'string',
      primary: true
    },
    class: {
      type: 'string',
      default: '',
      final: true
    },
    status: {
      type: 'string'
    },
    eventId: {
      type: 'string',
      final: true
    },
    scheduled_at: {
      type: 'number',
      final: true
    },
    payment: {
      type: 'object',
      properties: {
        paid: {
          type: 'boolean'
        },
        onlinePayment: {
          type: 'boolean'
        }
      }
    },
    competitor: {
      type: 'object',
      properties: {
        id: {
          type: 'string'
        },
        type: { // Unused?
          type: 'string'
        },
        name: {
          type: 'string'
        },
        avatar: {
          type: 'string'
        },
        metadata: {
          type: 'object'
        }
      }
    },
    rounds: {
      type: 'array',
      items: { $ref: '#/definitions/round' }
    },
    results: {
      type: 'array',
      items: { $ref: '#/definitions/result' }
    }
  },
  definitions: {
    result: {
      type: 'object',
      properties: {
        type: {
          type: 'string',
          default: ''
        },
        value: {
          type: 'number',
          default: 0
        },
        value_text: {
          type: ['string', 'null'],
          default: ''
        },
        comment: {
          type: 'string',
          default: ''
        }
      }
    },
    step: {
      type: 'object',
      properties: {
        state: {
          type: 'string'
        },
        timestamp: {
          type: 'number',
          default: 0
        },
        elapsed: {
          type: 'number',
          default: 0
        },
        comment: {
          type: 'string',
          default: ''
        },
        results: {
          type: 'array',
          items: { $ref: '#/definitions/result' }
        }
      }
    },
    round: {
      type: 'object',
      properties: {
        comment: {
          type: 'string',
          default: ''
        },
        results: {
          type: 'array',
          items: { $ref: '#/definitions/result' }
        },
        steps: {
          type: 'array',
          items: { $ref: '#/definitions/step' }
        }
      }
    }
  },
  required: ['id', 'eventId', 'competitor'],
  indexes: ['id']
}

const eventSchema = {
  version: 1,
  title: 'Event schema',
  keyCompression: true,
  type: 'object',
  properties: {
    id: {
      type: 'string',
      primary: true
    },
    type: {
      type: 'string',
      default: 'bloodtracking',
      final: true
    },
    categories: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          type: {
            type: 'string',
            default: ''
          },
          value: {
            type: 'string',
            default: ''
          }
        }
      }
    },
    date: {
      from: {
        type: 'number'
      },
      to: {
        type: 'number'
      }
    },
    title: {
      type: 'string'
    },
    description: {
      type: 'string',
      default: ''
    },
    results: {
      type: 'array',
      items: { $ref: '#/definitions/result' }
    }
  },
  definitions: {
    result: {
      type: 'object',
      properties: {
        type: {
          type: 'string',
          default: ''
        },
        value: {
          type: 'number',
          default: 0
        },
        value_text: {
          type: ['string', 'null'],
          default: ''
        },
        comment: {
          type: 'string',
          default: ''
        }
      }
    }
  },
  required: ['id', 'title'],
  indexes: ['id']
}

const testSchema = {
  version: 0,
  title: 'Test schema',
  keyCompression: true,
  type: 'object',
  properties: {
    type: {
      type: 'string',
      primary: true
    },
    max_rounds: {
      type: 'number',
      default: 1
    },
    additional_steps: {
      type: 'array',
      items: { $ref: '#/definitions/step' }
    },
    steps: {
      type: 'array',
      items: { $ref: '#/definitions/step' }
    },
    evaluation: {
      type: 'object',
      properties: {
        event: {
          type: 'array',
          items: { $ref: '#/definitions/evaluation' }
        },
        round: {
          type: 'array',
          items: { $ref: '#/definitions/evaluation' }
        }
      }
    }
  },
  definitions: {
    step: {
      type: 'object',
      properties: {
        state: {
          type: 'string'
        },
        title: {
          type: 'string'
        },
        evaluation: {
          $ref: '#/definitions/evaluation'
        }
      }
    },
    evaluation: {
      type: 'object',
      properties: {
        scope: {
          type: 'string'
        },
        title: {
          type: 'string'
        },
        tooltip: {
          type: ['string', 'null']
        },
        before: {
          type: ['boolean', 'null']
        },
        inputs: {
          type: 'array',
          items: {
            $ref: '#/definitions/input'
          }
        }
      }
    },
    input: {
      type: 'object',
      properties: {
        scope: {
          type: 'string'
        },
        type: {
          type: 'string'
        },
        value: {
          type: ['string', 'array']
        }
      }
    }
  },
  required: ['type'],
  indexes: ['type']
}

const noseworkSchema = {
  version: 0,
  title: 'Nosework schema',
  keyCompression: true,
  type: 'object',
  properties: {
    key: {
      type: 'string',
      primary: true
    },
    value: {
      // type: 'string'
    }
  },
  required: ['key', 'value'],
  indexes: ['key']
}

const barkSchema = {
  version: 0,
  title: 'Bark schema',
  keyCompression: true,
  type: 'object',
  properties: {
    key: {
      type: 'string',
      primary: true
    },
    timestamp: {
      type: 'number',
      default: 0
    },
    registration: {
      type: 'number',
      default: 0
    },
    round: {
      type: 'number',
      default: 0
    },
    count: {
      type: 'number',
      default: 0
    }
  },
  required: ['key', 'round', 'registration', 'timestamp', 'count'],
  indexes: ['key', 'round', 'registration']
}

export default {
  change: {
    schema: changeSchema,
    subscribe: false,
    internal: true
  },
  nosework: {
    schema: noseworkSchema,
    subscribe: false,
    internal: true
  },
  bark: {
    schema: barkSchema,
    subscribe: false
  },
  test: {
    schema: testSchema,
    subscribe: false
  },
  registration: {
    schema: registrationSchema,
    migrationStrategies: {
      1: (oldDoc) => {
        oldDoc.status = 'pending'
        return oldDoc
      },
      2: (oldDoc) => {
        oldDoc.payment = {
          paid: false,
          onlinePayment: false
        }

        return oldDoc
      },
      3: (oldDoc) => {
        oldDoc.class = ''
        return oldDoc
      }
    }
  },
  event: {
    schema: eventSchema,
    migrationStrategies: {
      1: (oldDoc) => {
        oldDoc.categories = []
        return oldDoc
      }
    }
  }
}
