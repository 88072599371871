<template>
  <v-container fluid>
    <v-row content="center" align-content="center">
      <v-col align="center">
        <v-progress-circular
          indeterminate
          color="primary"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col align="center">
        {{ $t(message) }}
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'LoadingProgress',

  props: {
    message: {
      type: String,
      default: 'Loading application...'
    }
  }
}
</script>
