import schemas from './schemas'

const _ = require('lodash')

class NoseworkCollections {
  constructor (context) {
    this.context = context
  }

  async init () {
    await Promise.all(Object.keys(schemas).map(async (name) => {
      const definition = schemas[name]

      const subscribe = _.get(definition, 'subscribe', true)
      const internal = _.get(definition, 'internal', false)

      if (Object.keys(definition).includes('subscribe')) {
        delete definition.subscribe
      }
      if (Object.keys(definition).includes('internal')) {
        delete definition.internal
      }

      // console.log(name, definition)
      this[name] = await this.createCollection(name, definition)

      if (subscribe) {
        /* this[name].insert$.subscribe((changeEvent) => { */
        /*   this.context.storage().onChanged(name, 'insert', changeEvent) */
        /* }) */
        this[name].update$.subscribe((changeEvent) => {
          this.context.storage().onChanged(name, 'update', changeEvent)
        })
        /* this[name].remove$.subscribe((changeEvent) => { */
        /*   this.context.storage().onChanged(name, 'remove', changeEvent) */
        /* }) */
      }

      if (!internal) {
        this[name].find().$.subscribe((items) => {
          const commitFnName = `set${name.substr(0, 1).toUpperCase()}${name.substr(1)}s`
          // console.log(commitFnName)
          this.context.vuex().commit(commitFnName, items)
        })
      }
    }))

    this.nosework.preInsert((plainData) => {
      // set age to 50 before saving
      plainData.value = JSON.stringify(plainData.value)
    }, false)

    return true
  }

  createCollection (name, schema) {
    return this.context.storage().db.collection({
      name,
      ...schema
    })
  }
}

export default ctx => new NoseworkCollections(ctx)
