import Vue from 'vue'
import VueRouter from 'vue-router'

const routes = [
  {
    path: '',
    name: 'Index',
    component: () => import(/* webpackChunkName: "index" */ '@/views/index')
  },

  {
    path: '/auth',
    name: 'Auth',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth'),
    children: [
      {
        path: 'login',
        name: 'AuthLogin',
        component: () => import(/* webpackChunkName: "AuthLogin" */ '@/views/auth/login')
      }
    ]
  },

  {
    path: '/event',
    name: 'Event',
    component: () => import(/* webpackChunkName: "event" */ '@/views/event'),
    children: [
      {
        path: '',
        name: 'EventList',
        component: () => import(/* webpackChunkName: "eventList" */ '@/views/event/list')
      },

      {
        path: ':eventId',
        name: 'EventId',
        component: () => import(/* webpackChunkName: "eventId" */ '@/views/event/id'),
        children: [
          {
            path: '',
            name: 'EventShow',
            component: () => import(/* webpackChunkName: "eventShow" */ '@/views/event/show')
          },

          {
            path: 'track-and-weather',
            name: 'TrackAndWeather',
            component: () => import(/* webpackChunkName: "eventTrackAndWeather" */ '@/views/event/track-and-weather')
          },

          {
            path: 'registration',
            name: 'Registration',
            component: () => import(/* webpackChunkName: "registration" */ '@/views/registration'),
            children: [
              {
                path: ':registrationId',
                name: 'RegistrationId',
                component: () => import(/* webpackChunkName: "registrationId" */ '@/views/registration/id'),
                children: [
                  {
                    path: '',
                    name: 'RegistrationShow',
                    component: () => import(/* webpackChunkName: "registrationShow" */ '@/views/registration/show')
                  },

                  {
                    path: 'round/:roundId',
                    name: 'Round',
                    component: () => import(/* webpackChunkName: "round" */ '@/views/round')
                  },

                  {
                    path: 'evaluation',
                    name: 'RegistrationEvaluation',
                    component: () => import(/* webpackChunkName: "registrationEvaluation" */ '@/views/registration/evaluation')
                  },

                  {
                    path: 'chase',
                    name: 'ChaseEvaluation',
                    component: () => import(/* webpackChunkName: "chaseEvaluation" */ '@/views/registration/chase')
                  },

                  {
                    path: 'evaluation/:extraId',
                    name: 'ExtraEvaluation',
                    component: () => import(/* webpackChunkName: "extraEvaluation" */ '@/views/registration/evaluation')
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
]

Vue.use(VueRouter)

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

export default router
