import { NoseworkPlatform } from './platform'

class NoseworkIos extends NoseworkPlatform {
  constructor (ctx) {
    super(ctx)
    console.log(this.context)
    // super.context.state().isMobile = true
  }
}

export default ctx => new NoseworkIos(ctx)
