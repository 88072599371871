import Vue from 'vue'

import VueDayjs from 'vue-dayjs-plugin'
import vuescroll from 'vuescroll'

import App from './App.vue'
import router from './router'
import store from './store'

import vuetify from './plugins/vuetify'
import nosework from './plugins/nosework.js'
import duration from './plugins/duration.js'
import heartbeats from './plugins/heartbeats'

import i18n from './i18n'

// You can set global config here.
Vue.use(vuescroll, {
  ops: {
    // The global config
  },
  name: 'vue-scroll' // customize component name, default -> vueScroll
})

Vue.config.productionTip = false

Vue.use(nosework, {
  store: {
    container: store
  },
  emitter: {
    useStore: true
  }
})

Vue.use(duration)
Vue.use(heartbeats)
Vue.use(VueDayjs)

new Vue({
  vuetify,
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
