import { addRxPlugin, createRxDatabase, isRxDatabase } from 'rxdb'

import { RxDBKeyCompressionPlugin } from 'rxdb/plugins/key-compression'
import { RxDBValidatePlugin } from 'rxdb/plugins/validate'
import { RxDBQueryBuilderPlugin } from 'rxdb/plugins/query-builder'
import { RxDBJsonDumpPlugin } from 'rxdb/plugins/json-dump'
import { RxDBMigrationPlugin } from 'rxdb/plugins/migration'

import chase from '@/assets/jsonnet/chase.jsonnet'
import bloodtracking from '@/assets/jsonnet/bloodtracking.jsonnet'

import collections from './collections'

const shortid = require('shortid')

shortid.characters('0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-:')

addRxPlugin(require('pouchdb-adapter-indexeddb'))

addRxPlugin(RxDBKeyCompressionPlugin)
addRxPlugin(RxDBValidatePlugin)
addRxPlugin(RxDBQueryBuilderPlugin)
addRxPlugin(RxDBJsonDumpPlugin)
addRxPlugin(RxDBMigrationPlugin)
// addRxPlugin(require('pouchdb-adapter-cordova-sqlite'))

class NoseworkStorage {
  constructor (context) {
    this.context = context
  }

  static getAdapter (platform) {
    const adapters = {
      web: {
        adapter: 'indexeddb',
        plugin: 'pouchdb-adapter-indexeddb'
      },
      electron: {
        adapter: 'indexeddb',
        plugin: 'pouchdb-adapter-indexeddb'
      },
      ios: {
        adapter: 'indexeddb',
        plugin: 'pouchdb-adapter-indexeddb'
        // adapter: 'node-cordova-sqlite',
        // plugin: 'pouchdb-adapter-cordova-sqlite'
      },
      android: {
        adapter: 'indexeddb',
        plugin: 'pouchdb-adapter-indexeddb'
        // adapter: 'node-cordova-sqlite',
        // plugin: 'pouchdb-adapter-cordova-sqlite'
      }
    }

    return adapters[platform].adapter
  }

  // eslint-disable-next-line class-methods-use-this
  parseAppState (rx) {
    if (!Array.isArray(rx)) {
      return rx
    }
    const appStateObj = {}
    rx.forEach((doc) => {
      Object.defineProperty(appStateObj, doc.key, {
        value: doc.value,
        enumerable: true,
        writable: true
      })
    })
    return appStateObj
  }

  async init () {
    try {
      this.db = await createRxDatabase({
        name: 'noseworkdb',
        adapter: NoseworkStorage.getAdapter(this.context.device().platform),
        revs_limit: 100
      })
      if (!isRxDatabase(this.db)) {
        // noinspection ExceptionCaughtLocallyJS
        throw new Error('Error creating database')
      }
      this.collections = collections(this.context)
      await this.collections.init()

      this.collections.test.atomicUpsert(chase)
      this.collections.test.atomicUpsert(bloodtracking)

      return true
    } catch (err) {
      console.log(err)
      return false
    }
  }

  async getLastAppState () {
    let appState
    try {
      appState = await this.context.storage().collections.nosework.find().exec()
      return this.parseAppState(appState)
    } catch (err) {
      appState = null
    }
    return appState
  }

  async onChanged (collection, type, event) {
    const changePayload = {
      id: `${collection}-${type}-${event.documentData.id}`,
      entity: collection,
      entityId: event.documentData.id
    }

    await this.context.storage().collections.change.atomicUpsert(changePayload)
    const changes = await this.collections.change.find().exec()
    const state = this.context.state()

    state.uploaded = changes.length === 0

    if (collection === 'registration' && event.documentData.status === 'completed') {
      state.showSyncWrapper = true
    }
  }

  populateLastAppState (lastAppState = {}) {
    return new Promise((resolve) => {
      Object.keys(lastAppState).filter(key => key[0] !== '_').forEach((key) => {
        if (typeof (lastAppState[key]) !== 'boolean') {
          this.context.state()[
            `abs${key[0].toUpperCase()}${key.substr(1)}`
          ] = lastAppState[key]
        } else {
          this.context.state()[key] = lastAppState[key]
        }
      })
      this.context.state().absLastUsed = Date.now()

      resolve(true)
    })
  }

  updateAppState (payload) {
    const promises = []

    Object.assign(payload, {
      lastUsed: Date.now()
    })

    Object.keys(payload).forEach((key) => {
      promises.push(this.context.storage().collections.nosework.atomicUpsert({
        key,
        value: payload[key]
      }))
    })

    return Promise.all(promises)
  }

  async synchronize () {
    this.context.state().downloaded = false
    this.context.state().uploaded = false

    this.context.state().downloading = true
    this.context.state().uploading = true

    let finished = 0

    this.context.vuex().commit('storage/setSyncState', 'started')
    this.context.vuex().commit('storage/setSyncStepsCount', 200)
    this.context.vuex().commit('storage/setSyncStepsFinished', 0)

    const changes = await this.collections.change.find().exec()
    const promises = []

    if (changes.length === 0 || !this.collections.registration) {
      finished += 90
      this.context.vuex().commit('storage/setSyncStepsFinished', finished)
    } else {
      changes.forEach((change) => {
        promises.push(this.collections[change.entity].findOne().where(
          'id'
        ).eq(
          change.entityId
        ).exec().then((result) => {
          if (result === null) {
            return true
          }
          return this.context.smoothcomp().upload(
            change.entity,
            result.toJSON()
          )
        }).then((res) => {
          if (res instanceof Error) {
            throw res
          }

          finished += 50 / changes.length
          return this.context.vuex().commit('storage/setSyncStepsFinished', finished)
        }).then(() => (
          this.collections.change.findOne().where('id').eq(change.id).remove()
        )))
      })

      await Promise.all(promises)
      await this.collections.change.importDump(
        await this.collections.change.dump()
      )

      finished += 40
    }

    await this.context.vuex().commit('storage/setSyncStepsFinished', finished)

    this.context.state().uploaded = true
    this.context.state().uploading = false

    promises.length = 0

    const collections = [
      'event',
      'registration'
    ]

    await this.context.vuex().commit('storage/setSyncStepsFinished', finished)

    const events = await this.context.smoothcomp().getEvents()

    if (events instanceof Error) {
      throw events
    }

    try {
      await this.collections.event.find().remove()
    } catch (err) {
      // We can ignore this
    }

    finished += 5

    await this.context.vuex().commit('storage/setSyncStepsFinished', finished)

    events.forEach((event) => {
      promises.push(new Promise((resolve) => {
        const allowed = [
          'id',
          'date',
          'title',
          'type',
          'description',
          'categories',
          'results'
        ]
        const parsed = {}

        Object.keys(event).forEach((prop) => {
          if (allowed.includes(prop)) {
            Object.defineProperty(parsed, prop, {
              value: event[prop],
              enumerable: true,
              writable: true
            })
          }
        })

        return resolve(parsed)
      }).then(parsed => this.collections.event.atomicUpsert(parsed)
      ).then(() => {
        finished += (90 / collections.length) / events.length
        return this.context.vuex().commit('storage/setSyncStepsFinished', finished)
      }))
    })

    const registrations = await this.context.smoothcomp().getRegistrations()

    if (registrations instanceof Error) {
      throw registrations
    }

    try {
      await this.collections.registration.find().remove()
    } catch (err) {
      // We can ignore this
    }

    finished += 5

    await this.context.vuex().commit('storage/setSyncStepsFinished', finished)

    registrations.forEach((registration) => {
      promises.push(new Promise((resolve) => {
        const allowed = [
          'payment',
          'class',
          'competitor',
          'eventId',
          'scheduled_at',
          'id',
          'status',
          'results',
          'rounds'
        ]

        const parsed = {}

        if (registration.rounds === undefined || registration.rounds.length === 0) {
          registration.rounds = [{
            steps: [],
            results: []
          }]
        }

        if (registration.results === undefined || registration.results.length === 0) {
          registration.results = []
        }

        Object.keys(registration).forEach((prop) => {
          if (allowed.includes(prop)) {
            Object.defineProperty(parsed, prop, {
              value: registration[prop],
              enumerable: true,
              writable: true
            })
          }
        })

        return resolve(parsed)
      }).then(parsed => this.collections.registration.atomicUpsert(parsed)).then(() => {
        finished += (90 / collections.length) / registrations.length
        return this.context.vuex().commit('storage/setSyncStepsFinished', finished)
      }))
    })

    this.context.state().touched = true

    return Promise.all(promises).then(() => {
      this.context.state().downloaded = true

      finished += 10
      return this.context.vuex().commit('storage/setSyncStepsFinished', finished)
    }).catch((err) => {
      console.log(err)
    }).finally(() => {
      this.context.state().downloading = false
      this.context.vuex().commit('storage/setSyncState', 'finished')
    })
  }
}

export default ctx => new NoseworkStorage(ctx)
