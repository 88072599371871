<template>
  <v-toolbar :elevation="1">
    <v-toolbar-title v-if="!!title"
      style="position: fixed; left: 0; width: 100%;"
      :class="[
        'body-2',
        'ml-0',
        'pl-0',
        'text-center',
      ]"
    >
      {{ $t(title) }}
    </v-toolbar-title>

      <v-toolbar-items>
        <v-tooltip bottom v-if="!!to">
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              color="white"
              v-bind="attrs"
              v-on="on"
              :to="to"
            >
              <v-icon color="primary">
                mdi-arrow-left
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('Back') }}</span>
        </v-tooltip>
      </v-toolbar-items>

      <div class="flex-grow-1"></div>

      <v-toolbar-items>
        <v-btn
          icon
          color="white"
          @click="toggleDrawer"
        >
          <v-icon color="grey">
            mdi-dots-vertical-circle-outline
          </v-icon>

          <v-badge
            overlap
            left
            color="none"
            v-if="!appState.synchronized"
          >
            <template #badge>
              <span style="background-color: white; border-radius: 100%">
                <v-icon color="orange" size="16">
                  mdi-sync-circle
                </v-icon>
              </span>
            </template>
          </v-badge>
        </v-btn>
      </v-toolbar-items>
  </v-toolbar>
</template>

<script>
export default {
  name: 'BackNavigation',

  props: {
    to: {
      type: String,
      default: null
    },

    title: {
      type: String,
      required: true
    },

    toggleDrawer: {
      type: Function,
      required: true
    }
  },

  methods: {
    async synchronize () {
      await this.$nosework.context.storage().synchronize()
    },

    async logout () {
      await this.$nosework.context.auth().logout()
    }
  }
}
</script>
