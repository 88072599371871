import NoseworkStorage from '../../storage'
import NoseworkAuth from '../../auth'
import syncFlow from './sync-flow'
import authFlow from './auth-flow.js'

const _ = require('lodash')
const shortid = require('shortid')

shortid.characters('0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-:')

class NoseworkAppFlow {
  constructor (context) {
    this.context = context
  }

  async init () {
    this.setListeners()
    await this.hasVuex()
    await this.hasStorage()
    await this.initAuth()
    await this.createApp()
    await this.initSync()
    return true
  }

  hasVuex () {
    return new Promise((resolve) => {
      if (!this.context.vuex() || typeof (this.context.vuex()._mutations) !== 'object') {
        resolve(setTimeout(this.hasVuex, 100))
      } else {
        this.context.state().hasVuex = true
        resolve(true)
      }
    })
  }

  async hasStorage () {
    const storage = NoseworkStorage(this.context)
    this.context.set('storage', storage)
    await this.context.storage().init()
    this.context.state().hasStorage = true
    return true
  }

  initAuth () {
    return new Promise((resolve) => {
      this.context.set('auth', new NoseworkAuth(this.context))
      authFlow(this.context).init()
      this.context.state().authReady = true
      resolve(true)
    })
  }

  /**
   * Get the last app state from the storage.
   * Two cases:
   * 1. If the app was authenticated, perform the preauthentication
   * 2. If the app was deauthenticating, do the deauthenticating stuff
   * 3. If the app was deauthenticated, set mustLogin to true
   * 4. If there was no app state, build a fresh app
   * @returns {Promise<void>}
   */
  async createApp () {
    const lastAppState = await this.context.storage().getLastAppState()
    if (lastAppState === null) {
      return this.buildFresh()
    }

    if (_.get(lastAppState, 'auth', null) !== null) {
      // console.log('76')
      await this.context.auth().authenticate({}, true)
    } else {
      this.context.state().mustLogin = true
    }

    await this.context.storage().populateLastAppState(this.context.storage().parseAppState(lastAppState))
    this.context.state().hasApp = true
    return true
  }

  async buildFresh () {
    this.context.state().fresh = true
    const freshId = shortid.generate()
    console.log(this.context.storage().collections)
    await this.context.storage().collections.nosework.upsert({
      key: 'appId',
      value: freshId
    })
    await this.context.storage().collections.nosework.upsert({
      key: 'user',
      value: null
    })
    await this.context.storage().collections.nosework.upsert({
      key: 'created',
      value: Date.now()
    })
    await this.context.storage().collections.nosework.upsert({
      key: 'updated',
      value: Date.now()
    })
    return this.createApp()
  }

  initSync () {
    return syncFlow(this.context).init()
  }

  setListeners () {
    this.context.on('authenticated', true, this.onAuthenticated)
    this.context.on('closing', true, this.onClosing)
  }

  onAuthenticated () {
    // // console.log('AUTHENTICATED')
    // this.context.state().mustDownload = true
  }

  onClosing () {}
}

export default ctx => new NoseworkAppFlow(ctx)
