
const shortid = require('shortid')

shortid.characters('0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-:')

const _ = require('lodash')

class NoseworkBloodTrackingTimer {
  constructor (context) {
    this.context = context
    this.timer = null
    this.actions = null
    this._milestones = []
    this._states = []
    this._actionInfo = {}
    this._timeSlots = {}
    this._history = []
    this.state = null
    this.loading = false
  }

  set milestones (value) {
    this._milestones = [0].concat(value.concat(value.map(i => i - 1)).sort())
    this._states = this.calculateStates()
    this._actionInfo = this.actionInfo()
    this._timeSlots = this.timeSlots()
  }

  get milestones () {
    return this._milestones
  }

  timeWithoutPauses (time = null) {
    const startedAt = _.get(_.find(this.actions, i => i.type === 'timer.started'), 'localTimeAt', null)

    if (startedAt === null) {
      return -1
    }

    const absMaxTime = Math.min(
      Date.now(),
      _.get(_.first(this.actions.filter(i => ['timer.aborted', 'timer.closed', 'timer.stopped'].includes(i.type))), 'localTimeAt', Date.now())
    )

    time = !time ? absMaxTime : time

    const totalPausedUntil = _.sumBy(this._states.filter(i => i.isPaused === true && i.from < time), (state) => {
      return (state.to - state.from)
    })

    const totalPausedNow = this.getStateAt(time).isPaused ? Date.now() - this.getStateAt(time).from : 0
    const totalPaused = totalPausedUntil + totalPausedNow

    const totalRunning = time - startedAt
    return totalRunning - totalPaused
  }

  mergeTimeSlots (slots) {
    let from
    let to

    const newSlots = []

    for (let i = 0; i < slots.length; i++) {
      let k = i

      from = slots[i].from
      to = slots[i].to

      for (let j = i + i; j < slots.length; j++) {
        if (slots[j].from === (to + 1)) {
          to = slots[j].to
          k = j + 1
        } else {
          break
        }
      }

      newSlots.push({
        from,
        to
      })

      if (k > i) { i = k }
    }

    return newSlots
  }

  timeSlots (ignoreClosed = false) {
    const slots = {}
    const states = this.calculateStates()

    // Object.defineProperty(slots, 'animal.spotted', {
    //   value: _.find(this.actions, i => i.type === 'timer.started') ? this.mergeTimeSlots([{
    //     from: _.find(this.actions, i => i.type === 'timer.started').localTimeAt,
    //     to: _.get(_.first(this.actions.filter(i => ['timer.aborted', 'timer.closed', 'timer.stopped'].includes(i.type))), 'localTimeAt', Date.now())
    //   }]) : [],
    //   enumerable: true,
    //   writable: true
    // })

    // Blood tracking

    Object.defineProperty(slots, 'length1.started', {
      value: this.mergeTimeSlots(states.filter(i => (ignoreClosed || i.isRunning) && i.canLength1).map((i) => {
        return {
          from: i.from,
          to: i.to
        }
      }).filter(i => i.from !== i.to)),
      enumerable: true,
      writable: true
    })

    Object.defineProperty(slots, 'angle1.started', {
      value: this.mergeTimeSlots(states.filter(i => (ignoreClosed || i.isRunning) && i.canAngle1).map((i) => {
        return {
          from: i.from,
          to: i.to
        }
      }).filter(i => i.from !== i.to)),
      enumerable: true,
      writable: true
    })

    Object.defineProperty(slots, 'length2.started', {
      value: this.mergeTimeSlots(states.filter(i => (ignoreClosed || i.isRunning) && i.canLength2).map((i) => {
        return {
          from: i.from,
          to: i.to
        }
      }).filter(i => i.from !== i.to)),
      enumerable: true,
      writable: true
    })

    Object.defineProperty(slots, 'angle2.started', {
      value: this.mergeTimeSlots(states.filter(i => (ignoreClosed || i.isRunning) && i.canAngle2).map((i) => {
        return {
          from: i.from,
          to: i.to
        }
      }).filter(i => i.from !== i.to)),
      enumerable: true,
      writable: true
    })

    Object.defineProperty(slots, 'length3.started', {
      value: this.mergeTimeSlots(states.filter(i => (ignoreClosed || i.isRunning) && i.canLength3).map((i) => {
        return {
          from: i.from,
          to: i.to
        }
      }).filter(i => i.from !== i.to)),
      enumerable: true,
      writable: true
    })

    Object.defineProperty(slots, 'angle3.started', {
      value: this.mergeTimeSlots(states.filter(i => (ignoreClosed || i.isRunning) && i.canAngle3).map((i) => {
        return {
          from: i.from,
          to: i.to
        }
      }).filter(i => i.from !== i.to)),
      enumerable: true,
      writable: true
    })

    Object.defineProperty(slots, 'length4.started', {
      value: this.mergeTimeSlots(states.filter(i => (ignoreClosed || i.isRunning) && i.canLength4).map((i) => {
        return {
          from: i.from,
          to: i.to
        }
      }).filter(i => i.from !== i.to)),
      enumerable: true,
      writable: true
    })

    Object.defineProperty(slots, 'angle4.started', {
      value: this.mergeTimeSlots(states.filter(i => (ignoreClosed || i.isRunning) && i.canAngle4).map((i) => {
        return {
          from: i.from,
          to: i.to
        }
      }).filter(i => i.from !== i.to)),
      enumerable: true,
      writable: true
    })

    Object.defineProperty(slots, 'length5.started', {
      value: this.mergeTimeSlots(states.filter(i => (ignoreClosed || i.isRunning) && i.canLength5).map((i) => {
        return {
          from: i.from,
          to: i.to
        }
      }).filter(i => i.from !== i.to)),
      enumerable: true,
      writable: true
    })

    Object.defineProperty(slots, 'angle5.started', {
      value: this.mergeTimeSlots(states.filter(i => (ignoreClosed || i.isRunning) && i.canAngle5).map((i) => {
        return {
          from: i.from,
          to: i.to
        }
      }).filter(i => i.from !== i.to)),
      enumerable: true,
      writable: true
    })

    Object.defineProperty(slots, 'lease.started', {
      value: this.mergeTimeSlots(states.filter(i => (ignoreClosed || i.isRunning) && i.isInkallRunning && i.canLease).map((i) => {
        return {
          from: i.from,
          to: i.to
        }
      }).filter(i => i.from !== i.to)),
      enumerable: true,
      writable: true
    })

    return slots
  }

  timeslotsToHours (slots) {
    const hoursAndMinutes = []

    slots.forEach((slot) => {
      // from/to
      for (let i = slot.from; Math.abs(slot.to - i) >= 500; i += 250) {
        const date = new Date(i)

        if (hoursAndMinutes.filter(i => i.hour === date.getHours() && i.minutes === date.getMinutes()).length > 0) {
          continue
        }

        hoursAndMinutes.push({
          hour: date.getHours(),
          minutes: date.getMinutes()
        })
      }
    })

    return _.uniq(hoursAndMinutes)
  }

  actionInfo (id = null) {
    const actionInfo = {}

    for (const action of this.actions) {
      if (id && action.id !== id) {
        continue
      }

      Object.defineProperty(actionInfo, action.id, {
        value: {},
        enumerable: true,
        writable: true
      })

      let deletable = false
      let minTime = action.localTimeAt
      let maxTime = action.localTimeAt

      if (action.type.substr(0, 6) === 'timer.') {
        deletable = action.type !== 'timer.started' && this.actions.filter(i => i.localTimeAt > action.localTimeAt && i.type.substr(0, 6) === 'timer.').length === 0
      }

      // Blood tracking
      if (action.type === 'length1.started') {
        deletable = this.actions.filter(i => i.localTimeAt > action.localTimeAt && ['angle1.started'].includes(i.type)).length === 0

        minTime = _.find(this.actions, i => i.type === 'timer.started').localTimeAt + 1
        maxTime = _.get(_.first(this.actions.filter(i => i.localTimeAt > action.localTimeAt && ['angle1.started'].includes(i.type))), 'localTimeAt', Date.now()) - 1
      }

      if (action.type === 'angle1.started') {
        deletable = this.actions.filter(i => i.localTimeAt > action.localTimeAt && ['length2.started'].includes(i.type)).length === 0

        minTime = _.find(this.actions, i => i.type === 'timer.started').localTimeAt + 1
        maxTime = _.get(_.first(this.actions.filter(i => i.localTimeAt > action.localTimeAt && ['length2.started'].includes(i.type))), 'localTimeAt', Date.now()) - 1
      }

      if (action.type === 'length2.started') {
        deletable = this.actions.filter(i => i.localTimeAt > action.localTimeAt && ['angle2.started'].includes(i.type)).length === 0

        minTime = _.find(this.actions, i => i.type === 'timer.started').localTimeAt + 1
        maxTime = _.get(_.first(this.actions.filter(i => i.localTimeAt > action.localTimeAt && ['angle2.started'].includes(i.type))), 'localTimeAt', Date.now()) - 1
      }

      if (action.type === 'angle2.started') {
        deletable = this.actions.filter(i => i.localTimeAt > action.localTimeAt && ['length3.started'].includes(i.type)).length === 0

        minTime = _.find(this.actions, i => i.type === 'timer.started').localTimeAt + 1
        maxTime = _.get(_.first(this.actions.filter(i => i.localTimeAt > action.localTimeAt && ['length3.started'].includes(i.type))), 'localTimeAt', Date.now()) - 1
      }

      if (action.type === 'length3.started') {
        deletable = this.actions.filter(i => i.localTimeAt > action.localTimeAt && ['angle3.started'].includes(i.type)).length === 0

        minTime = _.find(this.actions, i => i.type === 'timer.started').localTimeAt + 1
        maxTime = _.get(_.first(this.actions.filter(i => i.localTimeAt > action.localTimeAt && ['angle3.started'].includes(i.type))), 'localTimeAt', Date.now()) - 1
      }

      if (action.type === 'angle3.started') {
        deletable = this.actions.filter(i => i.localTimeAt > action.localTimeAt && ['length4.started'].includes(i.type)).length === 0

        minTime = _.find(this.actions, i => i.type === 'timer.started').localTimeAt + 1
        maxTime = _.get(_.first(this.actions.filter(i => i.localTimeAt > action.localTimeAt && ['length4.started'].includes(i.type))), 'localTimeAt', Date.now()) - 1
      }

      if (action.type === 'length4.started') {
        deletable = this.actions.filter(i => i.localTimeAt > action.localTimeAt && ['angle4.started'].includes(i.type)).length === 0

        minTime = _.find(this.actions, i => i.type === 'timer.started').localTimeAt + 1
        maxTime = _.get(_.first(this.actions.filter(i => i.localTimeAt > action.localTimeAt && ['angle4.started'].includes(i.type))), 'localTimeAt', Date.now()) - 1
      }

      if (action.type === 'angle4.started') {
        deletable = this.actions.filter(i => i.localTimeAt > action.localTimeAt && ['length5.started'].includes(i.type)).length === 0

        minTime = _.find(this.actions, i => i.type === 'timer.started').localTimeAt + 1
        maxTime = _.get(_.first(this.actions.filter(i => i.localTimeAt > action.localTimeAt && ['length5.started'].includes(i.type))), 'localTimeAt', Date.now()) - 1
      }

      if (action.type === 'length5.started') {
        deletable = this.actions.filter(i => i.localTimeAt > action.localTimeAt && ['angle5.started'].includes(i.type)).length === 0

        minTime = _.find(this.actions, i => i.type === 'timer.started').localTimeAt + 1
        maxTime = _.get(_.first(this.actions.filter(i => i.localTimeAt > action.localTimeAt && ['angle5.started'].includes(i.type))), 'localTimeAt', Date.now()) - 1
      }

      if (action.type === 'angle5.started') {
        deletable = this.actions.filter(i => i.localTimeAt > action.localTimeAt && ['length6.started'].includes(i.type)).length === 0

        minTime = _.find(this.actions, i => i.type === 'timer.started').localTimeAt + 1
        maxTime = _.get(_.first(this.actions.filter(i => i.localTimeAt > action.localTimeAt && ['length6.started'].includes(i.type))), 'localTimeAt', Date.now()) - 1
      }

      //

      if (['lease.started', 'animal.spotted'].includes(action.type)) {
        deletable = true
      }

      if (action.type === 'animal.spotted') {
        minTime = _.find(this.actions, i => i.type === 'timer.started').localTimeAt + 1
        maxTime = Math.min(
          Date.now(),
          _.get(_.first(this.actions.filter(i => ['timer.stopped', 'timer.closed', 'timer.aborted'].includes(i.type) && i.localTimeAt > action.localTimeAt)), 'localTimeAt', Date.now())
        )
      }

      if (action.type === 'timer.started') {
        minTime = new Date(action.localTimeAt)
        minTime.setHours(0)
        minTime.setMinutes(0)
        minTime.setMilliseconds(0)
        minTime = minTime.getTime()
        maxTime = _.get(_.first(this.actions.filter(i => i.localTimeAt > action.localTimeAt && ['slag.started'])), 'localTimeAt', Date.now()) - 1
      }

      if (action.type === 'timer.aborted') {
        minTime = _.get(_.last(this.actions.filter(i => i.localTimeAt < action.localTimeAt)), 'localTimeAt', action.localTimeAt)
        maxTime = Date.now()
      }

      const state = _.find(this._states, i => i.from === action.localTimeAt)

      Object.assign(actionInfo[action.id], {
        state: () => state,
        deletable,
        minTime,
        maxTime: !isNaN(maxTime) ? maxTime : Date.now(),
        time: action.localTimeAt,
        stepId: action.stepId,
        relativeStart: this.timeWithoutPauses(action.localTimeAt)
      })
    }

    return actionInfo
  }

  calculateStates () {
    const states = []

    for (const milestone of this.milestones) {
      const next = _.first(this.milestones.filter(i => i > milestone))

      if (next === milestone + 1) {
        continue
      }

      const state = {}

      const actionsSoFar = this.actions.filter(i => i.localTimeAt <= milestone).map(i => i.type)
      const isFresh = actionsSoFar.length === 0
      const isAborted = this.actions.filter(i => i.type === 'timer.aborted').length === 1
      const isClosed = this.actions.filter(i => i.type === 'timer.closed').length === 1
      const isStopped = this.actions.filter(i => i.type === 'timer.stopped').length === 1
      const isFinished = isAborted || isClosed || isStopped
      const isPaused = !isFresh && _.last(actionsSoFar.filter(i => i.substr(0, 6) === 'timer.')) === 'timer.paused'
      const isRunning = !isFresh && !isFinished && _.last(actionsSoFar.filter(i => i.substr(0, 6) === 'timer.')) !== 'timer.paused'

      // Blood tracking

      const hasLength1 = this.actions.filter(i => ['length1.started'].includes(i.type)).length > 0
      const isLength1Running = hasLength1 && this.actions.filter(i => i.type === 'length1.started' && i.localTimeAt <= milestone).length === 1 && this.actions.filter(i => i.type === 'length1.ended' && i.localTimeAt <= milestone).length === 0
      const length1Id = hasLength1 && isLength1Running ? _.first(this.actions.filter(i => i.type === 'length1.started' && i.localTimeAt <= milestone)).stepId : null

      const hasAngle1 = this.actions.filter(i => ['angle1.started'].includes(i.type)).length > 0
      const isAngle1Running = hasAngle1 && this.actions.filter(i => i.type === 'angle1.started' && i.localTimeAt <= milestone).length === 1 && this.actions.filter(i => i.type === 'angle1.ended' && i.localTimeAt <= milestone).length === 0
      const angle1Id = hasAngle1 && isAngle1Running ? _.first(this.actions.filter(i => i.type === 'angle1.started' && i.localTimeAt <= milestone)).stepId : null

      const hasLength2 = this.actions.filter(i => ['length2.started'].includes(i.type)).length > 0
      const isLength2Running = hasLength2 && this.actions.filter(i => i.type === 'length2.started' && i.localTimeAt <= milestone).length === 1 && this.actions.filter(i => i.type === 'length2.ended' && i.localTimeAt <= milestone).length === 0
      const length2Id = hasLength2 && isLength2Running ? _.first(this.actions.filter(i => i.type === 'length2.started' && i.localTimeAt <= milestone)).stepId : null

      const hasAngle2 = this.actions.filter(i => ['angle2.started'].includes(i.type)).length > 0
      const isAngle2Running = hasAngle2 && this.actions.filter(i => i.type === 'angle2.started' && i.localTimeAt <= milestone).length === 1 && this.actions.filter(i => i.type === 'angle2.ended' && i.localTimeAt <= milestone).length === 0
      const angle2Id = hasAngle2 && isAngle2Running ? _.first(this.actions.filter(i => i.type === 'angle2.started' && i.localTimeAt <= milestone)).stepId : null

      const hasLength3 = this.actions.filter(i => ['length3.started'].includes(i.type)).length > 0
      const isLength3Running = hasLength3 && this.actions.filter(i => i.type === 'length3.started' && i.localTimeAt <= milestone).length === 1 && this.actions.filter(i => i.type === 'length3.ended' && i.localTimeAt <= milestone).length === 0
      const length3Id = hasLength3 && isLength3Running ? _.first(this.actions.filter(i => i.type === 'length3.started' && i.localTimeAt <= milestone)).stepId : null

      const hasAngle3 = this.actions.filter(i => ['angle3.started'].includes(i.type)).length > 0
      const isAngle3Running = hasAngle3 && this.actions.filter(i => i.type === 'angle3.started' && i.localTimeAt <= milestone).length === 1 && this.actions.filter(i => i.type === 'angle3.ended' && i.localTimeAt <= milestone).length === 0
      const angle3Id = hasAngle3 && isAngle3Running ? _.first(this.actions.filter(i => i.type === 'angle3.started' && i.localTimeAt <= milestone)).stepId : null

      const hasLength4 = this.actions.filter(i => ['length4.started'].includes(i.type)).length > 0
      const isLength4Running = hasLength4 && this.actions.filter(i => i.type === 'length4.started' && i.localTimeAt <= milestone).length === 1 && this.actions.filter(i => i.type === 'length4.ended' && i.localTimeAt <= milestone).length === 0
      const length4Id = hasLength4 && isLength4Running ? _.first(this.actions.filter(i => i.type === 'length4.started' && i.localTimeAt <= milestone)).stepId : null

      const hasAngle4 = this.actions.filter(i => ['angle4.started'].includes(i.type)).length > 0
      const isAngle4Running = hasAngle4 && this.actions.filter(i => i.type === 'angle4.started' && i.localTimeAt <= milestone).length === 1 && this.actions.filter(i => i.type === 'angle4.ended' && i.localTimeAt <= milestone).length === 0
      const angle4Id = hasAngle4 && isAngle4Running ? _.first(this.actions.filter(i => i.type === 'angle4.started' && i.localTimeAt <= milestone)).stepId : null

      const hasLength5 = this.actions.filter(i => ['length5.started'].includes(i.type)).length > 0
      const isLength5Running = hasLength5 && this.actions.filter(i => i.type === 'length5.started' && i.localTimeAt <= milestone).length === 1 && this.actions.filter(i => i.type === 'length5.ended' && i.localTimeAt <= milestone).length === 0
      const length5Id = hasLength5 && isLength5Running ? _.first(this.actions.filter(i => i.type === 'length5.started' && i.localTimeAt <= milestone)).stepId : null

      const hasAngle5 = this.actions.filter(i => ['angle5.started'].includes(i.type)).length > 0
      const isAngle5Running = hasAngle5 && this.actions.filter(i => i.type === 'angle5.started' && i.localTimeAt <= milestone).length === 1 && this.actions.filter(i => i.type === 'angle5.ended' && i.localTimeAt <= milestone).length === 0
      const angle5Id = hasAngle5 && isAngle5Running ? _.first(this.actions.filter(i => i.type === 'angle5.started' && i.localTimeAt <= milestone)).stepId : null

      const hasLease = this.actions.filter(i => ['lease.started'].includes(i.type)).length > 0
      const isLeaseRunning = hasAngle5 && this.actions.filter(i => i.type === 'lease.started' && i.localTimeAt <= milestone).length === 1 && this.actions.filter(i => i.type === 'lease.ended' && i.localTimeAt <= milestone).length === 0
      const leaseId = hasLease && isLeaseRunning ? _.first(this.actions.filter(i => i.type === 'lease.started' && i.localTimeAt <= milestone)).stepId : null

      const lowestStep = (isFinished && !isAborted ? 'finished' : (isAborted ? 'aborted' : (isAngle5Running ? 'angle5' : (isLength5Running ? 'length5' : (isAngle4Running ? 'angle4' : (isLength4Running ? 'length4' : (isAngle3Running ? 'angle3' : (isLength3Running ? 'length3' : (isAngle2Running ? 'angle2' : (isLength2Running ? 'length2' : (isAngle1Running ? 'angle1' : (isLength1Running ? 'angle1' : (isRunning ? 'running' : (isPaused ? 'paused' : 'fresh'))))))))))))))

      console.log(isLength1Running)

      const lowestStepId = null

      switch (lowestStep) {
        // case 'lease':
        //   lowestStepId = leaseId
        //   break
        // case 'inkall':
        //   lowestStepId = inkallId
        //   break
        // case 'upptag':
        //   lowestStepId = upptagId
        //   break
        // case 'slag':
        //   lowestStepId = slagId
        //   break
        default:
          break
      }

      Object.assign(state, {
        actionsSoFar,
        from: milestone,
        get to () {
          if (next !== null && next !== undefined) {
            return next
          }

          return Date.now()
        },
        isRunning,
        isPaused,
        isFresh,
        isAborted,
        canPause: !isFresh && isRunning,
        canRestart: !isFresh && isPaused,

        // Blood tracking

        canLength1: isRunning && !hasLength1,
        hasLength1,
        isLength1Running,
        length1Id,

        canAngle1: !hasAngle1 && isLength1Running,
        hasAngle1,
        isAngle1Running,
        angle1Id,

        canLength2: !hasLength2 && isAngle1Running,
        hasLength2,
        isLength2Running,
        length2Id,

        canAngle2: !hasAngle2 && isLength2Running,
        hasAngle2,
        isAngle2Running,
        angle2Id,

        canLength3: !hasLength3 && isAngle2Running,
        hasLength3,
        isLength3Running,
        length3Id,

        canAngle3: !hasAngle3 && isLength3Running,
        hasAngle3,
        isAngle3Running,
        angle3Id,

        canLength4: !hasLength4 && isAngle3Running,
        hasLength4,
        isLength4Running,
        length4Id,

        canAngle4: !hasAngle4 && isLength4Running,
        hasAngle4,
        isAngle4Running,
        angle4Id,

        canLength5: !hasLength5 && isAngle4Running,
        hasLength5,
        isLength5Running,
        length5Id,

        canAngle5: !hasAngle5 && isLength5Running,
        hasAngle5,
        isAngle5Running,
        angle5Id,

        canLease: !hasLease && hasAngle5 && isAngle5Running,
        hasLease,
        isLeaseRunning,
        leaseId,

        //

        canSpotAnimal: isRunning,
        lowestStep,
        lowestStepId,
        isStopped,
        isClosed,
        isFinished,
        get duration () {
          const to = Math.min(Date.now(), next)
          return to - milestone
        }
      })
      states.push(state)
    }

    this.state = _.last(states)

    return states
  }

  getStateAt (time) {
    return _.last(this._states.filter(i => i.from <= time))
  }

  async load (timerDoc) {
    await new Promise((resolve) => {
      this.context.storage().collections.timer.find({
        selector: { id: { $eq: timerDoc.id } }
      }).$.subscribe((timer) => {
        this.timer = timerDoc
      })

      this.context.storage().collections.action.find({
        selector: { timer: { $eq: timerDoc.id } }
      }).$.subscribe((actions) => {
        this.actions = _.sortBy(actions, ['localTimeAt'])
        this.milestones = actions.map(i => i.localTimeAt)

        this.loading = false
      })
      resolve(true)
    })

    return this
  }

  get loaded () {
    return !!this.timer && !!this.actions
  }

  get steps () {
    return {}
  }

  get shouldStart () {
    return this.milestones.length === 1
  }

  async start () {
    this.loading = true

    if (!this.shouldStart) {
      return true
    }

    const action = {
      id: shortid.generate(),
      type: 'timer.started',
      localTimeAt: Date.now(),
      direction: 'start',
      loggedAt: Date.now(),
      timer: this.timer.id,
      stepId: shortid.generate()
    }

    await this.context.storage().collections.action.atomicUpsert(action)
  }

  async pause (time = null) {
    this.loading = true

    time = !time ? Date.now() : time
    const state = this.getStateAt(time)
    if (!state.canPause) {
      throw new Error('Cannot pause')
    }

    const action = {
      id: shortid.generate(),
      type: 'timer.paused',
      localTimeAt: time,
      direction: 'start',
      timer: this.timer.id,
      loggedAt: Date.now(),
      stepId: shortid.generate()
    }

    await this.context.storage().collections.action.atomicUpsert(action)

    this._history.push(action.id)
  }

  async restart (time = null) {
    this.loading = true

    time = !time ? Date.now() : time
    const state = this.getStateAt(time)
    if (!state.canRestart) {
      throw new Error('Cannot restart')
    }

    const action = {
      id: shortid.generate(),
      type: 'timer.restarted',
      localTimeAt: time,
      direction: 'start',
      timer: this.timer.id,
      loggedAt: Date.now(),
      stepId: shortid.generate()
    }

    await this.context.storage().collections.action.atomicUpsert(action)

    this._history.push(action.id)
  }

  // async animalSpotted (time = null, comment = '') {
  //   this.loading = true

  //   time = !time ? Date.now() : time
  //   const state = this.getStateAt(time)
  //   if (!state.canSpotAnimal) {
  //     throw new Error('Cannot spot animal')
  //   }

  //   const action = {
  //     id: shortid.generate(),
  //     type: 'animal.spotted',
  //     localTimeAt: time,
  //     direction: 'start',
  //     timer: this.timer.id,
  //     comment,
  //     loggedAt: Date.now(),
  //     stepId: shortid.generate()
  //   }

  //   await this.context.storage().collections.action.atomicUpsert(action)

  //   this._history.push(action.id)
  // }

  async startSlag (time = null) {
    this.loading = true

    time = !time ? Date.now() : time
    const state = this.getStateAt(time)
    if (!state.canSlag) {
      throw new Error('Cannot slag')
    }

    const action = {
      id: shortid.generate(),
      type: 'slag.started',
      localTimeAt: time,
      direction: 'start',
      timer: this.timer.id,
      loggedAt: Date.now(),
      stepId: shortid.generate()
    }

    await this.context.storage().collections.action.atomicUpsert(action)

    this._history.push(action.id)
  }

  async startLength1 (time = null) {
    this.loading = true

    time = !time ? Date.now() : time

    const state = this.getStateAt(time)

    if (!state.canLength1) {
      throw new Error('Cannot start length 1')
    }

    const action = {
      id: shortid.generate(),
      type: 'length1.started',
      localTimeAt: time,
      direction: 'start',
      timer: this.timer.id,
      loggedAt: Date.now(),
      stepId: shortid.generate()
    }

    await this.context.storage().collections.action.atomicUpsert(action)

    this._history.push(action.id)
  }

  async startAngle1 (time = null) {
    this.loading = true

    time = !time ? Date.now() : time

    const state = this.getStateAt(time)

    if (!state.canAngle1) {
      throw new Error('Cannot start angle 1')
    }

    const action = {
      id: shortid.generate(),
      type: 'angle1.started',
      localTimeAt: time,
      direction: 'start',
      timer: this.timer.id,
      loggedAt: Date.now(),
      stepId: shortid.generate()
    }

    await this.context.storage().collections.action.atomicUpsert(action)

    this._history.push(action.id)
  }

  async startLength2 (time = null) {
    this.loading = true

    time = !time ? Date.now() : time

    const state = this.getStateAt(time)

    if (!state.canLength2) {
      throw new Error('Cannot start length 2')
    }

    const action = {
      id: shortid.generate(),
      type: 'length2.started',
      localTimeAt: time,
      direction: 'start',
      timer: this.timer.id,
      loggedAt: Date.now(),
      stepId: shortid.generate()
    }

    await this.context.storage().collections.action.atomicUpsert(action)

    this._history.push(action.id)
  }

  async startAngle2 (time = null) {
    this.loading = true

    time = !time ? Date.now() : time

    const state = this.getStateAt(time)

    if (!state.canAngle2) {
      throw new Error('Cannot start angle 2')
    }

    const action = {
      id: shortid.generate(),
      type: 'angle2.started',
      localTimeAt: time,
      direction: 'start',
      timer: this.timer.id,
      loggedAt: Date.now(),
      stepId: shortid.generate()
    }

    await this.context.storage().collections.action.atomicUpsert(action)

    this._history.push(action.id)
  }

  async startLength3 (time = null) {
    this.loading = true

    time = !time ? Date.now() : time

    const state = this.getStateAt(time)

    if (!state.canLength3) {
      throw new Error('Cannot start length 3')
    }

    const action = {
      id: shortid.generate(),
      type: 'length3.started',
      localTimeAt: time,
      direction: 'start',
      timer: this.timer.id,
      loggedAt: Date.now(),
      stepId: shortid.generate()
    }

    await this.context.storage().collections.action.atomicUpsert(action)

    this._history.push(action.id)
  }

  async startAngle3 (time = null) {
    this.loading = true

    time = !time ? Date.now() : time

    const state = this.getStateAt(time)

    if (!state.canAngle3) {
      throw new Error('Cannot start angle 3')
    }

    const action = {
      id: shortid.generate(),
      type: 'angle3.started',
      localTimeAt: time,
      direction: 'start',
      timer: this.timer.id,
      loggedAt: Date.now(),
      stepId: shortid.generate()
    }

    await this.context.storage().collections.action.atomicUpsert(action)

    this._history.push(action.id)
  }

  async startLength4 (time = null) {
    this.loading = true

    time = !time ? Date.now() : time

    const state = this.getStateAt(time)

    if (!state.canLength4) {
      throw new Error('Cannot start length 4')
    }

    const action = {
      id: shortid.generate(),
      type: 'length4.started',
      localTimeAt: time,
      direction: 'start',
      timer: this.timer.id,
      loggedAt: Date.now(),
      stepId: shortid.generate()
    }

    await this.context.storage().collections.action.atomicUpsert(action)

    this._history.push(action.id)
  }

  async startAngle4 (time = null) {
    this.loading = true

    time = !time ? Date.now() : time

    const state = this.getStateAt(time)

    if (!state.canAngle4) {
      throw new Error('Cannot start angle 4')
    }

    const action = {
      id: shortid.generate(),
      type: 'angle4.started',
      localTimeAt: time,
      direction: 'start',
      timer: this.timer.id,
      loggedAt: Date.now(),
      stepId: shortid.generate()
    }

    await this.context.storage().collections.action.atomicUpsert(action)

    this._history.push(action.id)
  }

  async startLength5 (time = null) {
    this.loading = true

    time = !time ? Date.now() : time

    const state = this.getStateAt(time)

    if (!state.canLength5) {
      throw new Error('Cannot start length 5')
    }

    const action = {
      id: shortid.generate(),
      type: 'length5.started',
      localTimeAt: time,
      direction: 'start',
      timer: this.timer.id,
      loggedAt: Date.now(),
      stepId: shortid.generate()
    }

    await this.context.storage().collections.action.atomicUpsert(action)

    this._history.push(action.id)
  }

  async startAngle5 (time = null) {
    this.loading = true

    time = !time ? Date.now() : time

    const state = this.getStateAt(time)

    if (!state.canAngle5) {
      throw new Error('Cannot start angle 5')
    }

    const action = {
      id: shortid.generate(),
      type: 'angle5.started',
      localTimeAt: time,
      direction: 'start',
      timer: this.timer.id,
      loggedAt: Date.now(),
      stepId: shortid.generate()
    }

    await this.context.storage().collections.action.atomicUpsert(action)

    this._history.push(action.id)
  }

  async startLease (time = null) {
    this.loading = true

    time = !time ? Date.now() : time

    const state = this.getStateAt(time)

    if (!state.canLease) {
      throw new Error('Cannot start lease')
    }

    const action = {
      id: shortid.generate(),
      type: 'lease.started',
      localTimeAt: time,
      direction: 'start',
      timer: this.timer.id,
      loggedAt: Date.now(),
      stepId: shortid.generate()
    }

    await this.context.storage().collections.action.atomicUpsert(action)

    this._history.push(action.id)

    const actionClose = {
      id: shortid.generate(),
      type: 'timer.closed',
      localTimeAt: time + 1,
      direction: 'start',
      timer: this.timer.id,
      loggedAt: Date.now(),
      stepId: shortid.generate()
    }

    await this.context.storage().collections.action.atomicUpsert(actionClose)

    this._history.push(actionClose.id)
  }

  async abortRound (time) {
    this.loading = true

    time = !time ? Date.now() : time

    const state = this.getStateAt(time)

    if (!state.isRunning) {
      throw new Error('Cannot abort')
    }

    const action = {
      id: shortid.generate(),
      type: 'timer.aborted',
      localTimeAt: time,
      direction: 'start',
      timer: this.timer.id,
      loggedAt: Date.now(),
      stepId: shortid.generate()
    }

    await this.context.storage().collections.action.atomicUpsert(action)

    this._history.push(action.id)
  }

  async deleteAction (id) {
    if (this._actionInfo[id].deletable) {
      await _.find(this.actions, i => i.id === id).remove()
      this._history = this._history.filter(i => i !== id)
    }
  }

  async updateAction (id, payload) {
    const changeFn = (old) => {
      Object.assign(old, payload)
      return old
    }
    const res = await _.find(this.actions, i => i.id === id).atomicUpdate(changeFn)

    this.actions = _.sortBy(this.actions, ['localTimeAt'])
    this.milestones = this.actions.map(i => i.localTimeAt)
    return res
  }
}

export default ctx => new NoseworkBloodTrackingTimer(ctx)
