import platform from './../../platform/index.js'

class NoseworkPlatformFlow {
  constructor (context) {
    this.context = context
  }

  async init () {
    await this.device()
    await this.platform()
    return true
  }

  async device () {
    const device = await this.context.preflight().getDevice()
    console.log(device)
    this.context.set('device', device)
    this.context.state().hasDevice = true
    return device.platform
  }

  async platform () {
    return await new Promise((resolve, reject) => {
      try {
        console.log('SLDSJDNSJDNJS')
        const p = platform(this.context.device().platform)
        this.context.set('platform', p(this.context))
        this.context.state().hasPlatform = true
        this.context.platform().init().then(() => {
          this.context.state().platformInitialized = true
          resolve(true)
        }).catch((err) => {
          this.context.logger().error(err)
          this.context.state().platformInitialized = false
          resolve(false)
        })
      } catch (err) {
        reject(err)
      }
    })
  }
}

export default ctx => new NoseworkPlatformFlow(ctx)
