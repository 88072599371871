var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-toolbar',{attrs:{"elevation":1}},[(!!_vm.title)?_c('v-toolbar-title',{class:[
      'body-2',
      'ml-0',
      'pl-0',
      'text-center' ],staticStyle:{"position":"fixed","left":"0","width":"100%"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.title))+" ")]):_vm._e(),_c('v-toolbar-items',[(!!_vm.to)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"white","to":_vm.to}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-arrow-left ")])],1)]}}],null,false,1392988269)},[_c('span',[_vm._v(_vm._s(_vm.$t('Back')))])]):_vm._e()],1),_c('div',{staticClass:"flex-grow-1"}),_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":"","color":"white"},on:{"click":_vm.toggleDrawer}},[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v(" mdi-dots-vertical-circle-outline ")]),(!_vm.appState.synchronized)?_c('v-badge',{attrs:{"overlap":"","left":"","color":"none"},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('span',{staticStyle:{"background-color":"white","border-radius":"100%"}},[_c('v-icon',{attrs:{"color":"orange","size":"16"}},[_vm._v(" mdi-sync-circle ")])],1)]},proxy:true}],null,false,2848262328)}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }