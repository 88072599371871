import android from './android'
import electron from './electron'
import web from './web'
import ios from './ios'

export default (platform) => {
  return (ctx) => {
    switch (platform) {
      case 'electron':
        return electron(ctx)
      case 'android':
        return android(ctx)
      case 'ios':
        return ios(ctx)
      default:
        return web(ctx)
    }
  }
}
