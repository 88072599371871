import axios from 'axios'
import _ from 'lodash'
import { Capacitor } from '@capacitor/core'

// const defaultUrl = 'http://smoothcomp.test/api/v1'
let defaultUrl = ''
if (Capacitor.getPlatform() === 'web' && (window.location.hostname === 'testapp.skkstart.se' || window.location.hostname === 'localhost')) {
  defaultUrl = 'https://skk-test.smoothcomp.dev'
} else {
  defaultUrl = 'https://skkstart.se'
}

class Smoothcomp {
  constructor (context) {
    this.context = context
    this._token = ''
    this.baseWebUrl = defaultUrl

    this.client = axios.create({
      baseURL: `${defaultUrl}/api/v1`
    })
  }

  get token () {
    try {
      return this.context.auth().user.token || null
    } catch (err) {
      // this.context.logger().info(err)
      return null
    }
  }

  get headers () {
    if (!this.token) {
      return {}
    }

    return {
      Authorization: `Bearer ${this.token}`
    }
  }

  set token (value) {
    this._token = value
  }

  authenticate ({ email, password }) {
    this.context.state().httpOn = true

    return this.client.post('login', {
      email,
      password
    }).then((res) => {
      if (!res || !_.get(res, 'data.token', null)) {
        throw new Error('Authorization failed')
      }

      const userRes = res.data.user

      Object.assign(userRes, { token: res.data.token })

      return userRes
    }).finally(() => {
      this.context.state().httpOn = false
    })
  }

  request (endpoint) {
    return this.client.get(
      endpoint,
      { headers: this.headers }
    ).then(res => (
      res.data
    )).catch((err) => {
      if (_.get(err, 'response.status', null) === 401) {
        this.context.auth().logout()
      } else {
        this.context.logger().error(err)
      }

      return err
    })
  }

  upload (type, payload) {
    let paid = false

    if (Object.prototype.hasOwnProperty.call(payload, 'payment')) {
      paid = payload.payment.paid
    }

    return this.client.post(
      `dogs/huntingtest/${type}`,
      {
        id: payload.id,
        status: payload.status,
        paid,
        type: payload.type,
        rounds: payload.rounds,
        results: payload.results
      },
      { headers: this.headers }
    ).then((res) => {
      this.context.logger().info(res)
      return res
    }).catch((err) => {
      this.context.logger().error(err)
      return err
    })
  }

  getEvents () {
    return this.request('dogs/huntingtest/events')
  }

  getRegistrations () {
    return this.request('dogs/huntingtest/registrations')
  }
}

export default (context, url = null) => {
  return new Smoothcomp(context, { url })
}
