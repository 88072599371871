import { NoseworkPlatform } from './platform'

class NoseworkElectron extends NoseworkPlatform {
  constructor (ctx) {
    super(ctx)
    this.initOperations = [
      'network',
      // 'battery',
      'watchForAppStateChange'
    ]
  }

  async watchForAppStateChange () {
    return await new Promise((resolve) => {
      this.context.on('appChange', (data) => {
        // @todo Store in storage
        this.context.state().absGracefulFinish = Date.now()
      })
      resolve(true)
    })
  }
}

export default ctx => new NoseworkElectron(ctx)
