export const state = () => ({
  show: false,
  to: '',
  title: ''
})

export const mutations = {
  setNavigation (s, { show, to, title } = { show: false, to: '', title: '' }) {
    s.to = show ? to : ''
    s.title = show ? title : ''
    s.show = show
  }
}
