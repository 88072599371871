class NoseworkPlatform {
  constructor (context) {
    this.context = context
    this.initOperations = [
      'network',
      'battery'
    ]
  }

  async init () {
    for (const op of this.initOperations) {
      await this[op]()
    }
    return true
  }

  async network () {
    const network = await this.context.preflight().getNetwork()

    this.context.set('network', network)

    return network
  }

  async battery () {
    const battery = await this.context.preflight().getBattery()

    this.context.set('battery', battery)

    return battery
  }

  get debounceMultiplier () {
    return 1
  }
}

export {
  NoseworkPlatform
}
