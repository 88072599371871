import { Plugins } from '@capacitor/core'
import { BatteryStatus } from '@ionic-native/battery-status'
const { Device, Network } = Plugins
const _ = require('lodash')

class NoseworkPreflight {
  constructor (context) {
    this.context = context

    this.networkHandler = null
    this.batteryHandler = null
    this._networkStatus = null
    this._batteryStatus = null
    this.platform = null
  }

  get canDeviceSynchronize () {
    if (!this.platform) {
      return false
    }

    if (this.platform === 'web') {
      return true
    }

    if (this.platform === 'electron') {
      return this.networkStatus.connected
    }

    return this.batteryStatus.isCharging || this.batteryStatus.batteryLevel >= 40
  }

  set networkStatus (value) {
    this._networkStatus = value
    this.context.state().isConnected = value.connected
  }

  get networkStatus () {
    return this._networkStatus
  }

  set batteryStatus (value) {
    this._batteryStatus = value

    const batteryLevel = _.get(value, 'batteryLevel', _.get(value, 'level', null))

    const payload = {
      level: batteryLevel !== null && batteryLevel > 1 ? batteryLevel / 100 : batteryLevel,
      charging: _.get(value, 'isCharging', _.get(value, 'isPlugged', null))
    }

    this.context.state().absBatteryLevel = _.get(payload, 'level', 1)
    this.context.state().isCharging = _.get(payload, 'level', true)
  }

  get batteryStatus () {
    return this._batteryStatus
  }

  /**
   * Get platform name
   * @returns {Promise<string>}
   */
  async getDevice (callback = null, onChangeFn = (status) => {}) {
    const res = await Device.getInfo()

    this.platform = res.platform
    this.uuid = res.uuid

    return callback ? callback(null, res) : res
  }

  /**
   * This method should return initial battery status, and then either:
   * 1. do not watch for battery sttaus change on web platform
   * 2. watch for battery status change normally for ios and electron
   * 3. watch for batterylow and batterycritical status change for android and, additionally, check the battery
   * status every 60 seconds
   * @param callback
   * @param onChangeFn
   * @returns {Promise<*>}
   */
  async getBattery (callback = null, onChangeFn = (battery) => {}) {
    if (this.platform && this.platform !== 'web') {
      this.batteryHandler = BatteryStatus.onChange().subscribe((status) => {
        this.batteryStatus = status
        onChangeFn(status)
      })
    }

    const res = await Device.getBatteryInfo()

    this.batteryStatus = res

    return callback ? callback(null, res) : res
  }

  /**
   * Get network data
   * @returns {Promise<Object>}
   */
  async getNetwork (callback = null, onChangeFn = (status) => {}) {
    if (this.platform) {
      this.networkHandler = Network.addListener('networkStatusChange', (status) => {
        this.networkStatus = status

        onChangeFn(status)
      })
    }

    const res = await Network.getStatus()

    this.networkStatus = res

    return callback ? callback(null, res) : res
  }
}

export {
  NoseworkPreflight
}
