/**
 *
 * @param {NoseworkContext} context
 * @constructor
 */
const NoseworkLogger = function (context) {
  this.context = context
}

NoseworkLogger.prototype = {
  handlers: {
    error: [
      (err, module, data) => {
        try {
          this.context.logger().info('Error', err, module, data)
        } catch (err) {}
      }
    ],

    info: [
      (msg, module, data) => {
        try {
          this.context.logger().info('Info', msg, module, data)
        } catch (err) {}
      }
    ]
  },
  /**
   *
   * @param {Error} err
   * @param {string} module
   * @param {*} data
   */
  error (err, module, data) {
    for (const handler of this.handlers.error) {
      handler(err, module, data)
    }
  },

  err (err, module, data) {
    return this.error(err, module, data)
  },
  /**
   *
   * @param {string} msg
   * @param {string} module
   * @param {*} data
   */
  info (msg, module, data) {
    for (const handler of this.handlers.info) {
      handler(msg, module, data)
    }
  }
}

export {
  NoseworkLogger
}
