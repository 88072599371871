import syncFlowChild from './sync-flow'

class NoseworkAuthFlow {
  constructor (context) {
    this.context = context
    // this.context.on('deauthenticated', false, this.onNotDeauthenticated)
  }

  onAuthenticating () {
    /**
     * 1. Set app state to authenticating
     */
  }

  onAuthenticated () {
    /**
     * 1. Set app state to authenticated
     * 2. Look for the previous app state corresponding to the authenticated user
     *    and update app state (ID, etc.)
     * 3. Ascertain the necessity to synchronize (upload/download)
     */
    this.context.state().canDeauthenticate = false
  }

  onNotAuthenticated () {
    /**
     * 1. If the reason is different than 401 Unauthorized, show an error toast
     */
  }

  onDeauthenticating () {
    /**
     * 1. Set the app state to deauthenticated
     * 2. Ascertain the necessity to upload data
     * 3. If data cannot be uploaded, save the upload request on the next connection,
     *    before the authentication
     */
    const mustUpload = syncFlowChild(this.context).checkSync({ download: false, upload: true })
    if (mustUpload) {
    } else {
      this.context.state().absLastState = 'deauthenticated'
      this.context.state().canDeauthenticate = true
      setTimeout(() => {
        this.context.state().canDeauthenticate = false
      }, 500)
    }
  }

  onDeauthenticated () {
    /**
     * 1. Make a fresh app state
     */

  }

  async init () {
    return await new Promise((resolve) => {
      this.context.on('authenticating', true, this.onAuthenticating.bind(this))
      this.context.on('authenticated', true, this.onAuthenticated.bind(this))
      this.context.on('authenticated', false, this.onNotAuthenticated.bind(this))

      this.context.on('deauthenticating', true, this.onDeauthenticating.bind(this))
      this.context.on('deauthenticated', true, this.onDeauthenticated.bind(this))

      resolve(true)
    })
  }
}

export default ctx => new NoseworkAuthFlow(ctx)
