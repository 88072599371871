import _ from 'lodash'

export default class NoseworkAuth {
  constructor (context) {
    this.context = context

    this.context.on('deauthenticating', true, () => {
      this.context.state().canDeauthenticate = false
      this.context.state().canDeauthenticate = true

      this.context.on('canDeauthenticate', false, () => {
        this.context.storage().updateAppState({
          lastState: 'deauthenticated'
        })
        this.context.state().canDeauthenticate = false
      })
    })

    this.context.on('authenticated', true, () => {
      this.context.storage().updateAppState({
        user: this.context.auth().user.email,
        lastState: 'authenticated'
      })
    })
  }

  get credentials () {
    return { token: _.get(this.user, 'credentials.token', null) }
  }

  /**
   * Perform full authentication flow.
   * @todo After preauthorization check if token has not expired
   * @param {string|null} email
   * @param {string|null} password
   * @param {boolean} preauthorize
   * @param callback
   * @return {Promise<*>}
   */
  async authenticate ({ email, password } = { email: null, password: null }, preauthorize = false, callback = null) {
    this.context.state().authenticating = true
    // preauthorize false is ignored
    const fn = async () => {
      if (!email || !password) {
        const preAuthRes = await this.preauthenticate()
        // no explicit authorization
        if (preAuthRes) {
          // auth data was found in the storage
          const user = preAuthRes
          this.writeToNosework(user)
          return (callback ? callback(null, user) : user)
        }

        return (callback ? callback(null, false) : false)
      }

      if (preauthorize) {
        const preAuthRes = await this.preauthenticate()

        if (preAuthRes) {
          // user already authenticated
          const err = new Error('Cannot relogin')

          if (callback) {
            return callback(err, null)
          }

          throw err
        }
      } else {
        // perform 'true' authorization
        try {
          const authRes = await this.authenticateRemote({ email, password })

          await this.context.storage().collections.nosework.upsert({
            key: 'auth',
            value: authRes
          })

          this.writeToNosework(authRes)

          return (callback ? callback(null, authRes) : authRes)
        } catch (err) {
          if (callback) {
            return callback(err, null)
          }

          throw err
        }
      }

      return callback(null, null)
    }

    const res = await fn()

    this.context.state().authenticating = false

    return res
  }

  /**
   * Preauthenticate - check whether user data are stored in the storage
   * @return {Promise<boolean|NoseworkUser>}
   */
  async preauthenticate () {
    const required = ['email', 'token']
    let user

    try {
      user = await this.context.storage().collections.nosework.findOne('auth').exec()
      user = user.value

      required.forEach((field) => {
        if (!Object.keys(user).includes(field)) {
          user = false
        }
      })
    } catch (err) {
      this.context.logger().error(err, 'auth')
      user = false
    }

    return user
  }

  /**
   * Authenticate using authentication provider
   * @param {string} email
   * @param {string} password
   * @return {Promise<boolean|NoseworkUser>}
   */
  async authenticateRemote ({ email, password }) {
    const res = await this.context.smoothcomp().authenticate({ email, password })

    if (res.token) {
      return res
    }

    const err = new Error(`User ${email} cannot be authorized`)
    this.context.logger().error(err, 'auth', { email })
    throw err
  }

  writeToNosework (user) {
    this.user = user

    this.context.state().hasUser = true
    this.context.state().isAuthenticated = true

    this.context.vuex().commit('auth/setAuthenticated', { user })
  }

  clearFromNosework () {
    this.user = null

    this.context.state().hasUser = false
    this.context.state().isAuthenticated = false

    this.context.vuex().commit('auth/setAuthenticated', { user: null })
  }

  async logout () {
    this.context.state().deauthenticating = true
    await this.context.storage().collections.nosework.upsert({
      key: 'auth',
      value: {}
    })

    this.clearFromNosework()
    this.context.state().deauthenticated = true
  }
}
