function duration (
  input,
  nosemicolons = false,
  showzero = false,
  showseconds = true,
  display = {
    h: 't',
    m: 'm',
    s: 's'
  }
) {
  let overtime = false
  let miliseconds = input
  if (miliseconds > 86400000) {
    miliseconds = 86400000
    overtime = true
  }

  if (miliseconds === null) {
    return showzero ? '<span class="seconds">0s</span>' : ''
  }

  let seconds = Math.floor(miliseconds / 1000)
  let minutes = Math.floor(seconds / 60)
  let hours = Math.floor(minutes / 60)

  seconds = seconds % 60
  minutes = minutes % 60

  let time = ''

  if (nosemicolons) {
    if (hours > 0) {
      time = `<span class="hours">${hours}${display.h} </span>`
    }

    if (minutes > 0) {
      time += `<span class="minutes">${minutes}${display.m} </span>`
    }

    if (showseconds) {
      if (seconds > 0) {
        time += `<span class="seconds">${seconds}${display.s}</span>`
      }
    }

    if (hours + minutes + seconds <= 0 && showzero) {
      return `<span class="seconds">0${display.s}</span>`
    }

    return `${overtime ? 'Mer än ' : ''}${time}`
  }

  if (hours + minutes + seconds <= 0 && !showzero) {
    return '--:--'
  }

  hours = `<span class="hours">${hours.toString().padStart(2, '0')}</span>`
  minutes = `<span class="minutes">:${minutes.toString().padStart(2, '0')}</span>`

  if (showseconds) {
    seconds = `<span class="seconds">:${seconds.toString().padStart(2, '0')}</span>`
  } else {
    seconds = ''
  }

  return `${[hours, minutes, seconds].join('')}${overtime ? '<span class="plus">+</span>' : ''}`
}

function durationFull (
  input,
  nosemicolons = false,
  showzero = false,
  showseconds = true
) {
  return duration(input, nosemicolons, showzero, showseconds, {
    h: ' tim',
    m: ' min',
    s: ' sec'
  })
}

const Duration = {
  duration,
  install (Vue) {
    Vue.filter('durationSemicolons', value => duration(value, false))
    Vue.filter('durationSemicolonsNoSeconds', value => duration(value, false, false, false))
    Vue.filter('durationNoSemicolons', value => duration(value, true))
    Vue.filter('durationNoSemicolonsZero', value => duration(value, true, true))
    Vue.filter('durationFullNoSemicolons', value => durationFull(value, true))
    Vue.filter('durationFullNoSemicolonsZero', value => durationFull(value, true, true))
  }
}

export default Duration
