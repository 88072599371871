export const state = () => ({
  loadState: 'unknown',
  synchronization: {
    state: 'unknown',
    shouldSync: false
  },
  stepsCount: -1,
  stepsFinished: -1
})

export const mutations = {
  setStateLoaded (state, loaded) {
    state.loadState = loaded
  },

  setSyncState (state, synchronizationState) {
    state.synchronization.state = synchronizationState
  },

  setShouldSync (state, shouldSync) {
    state.synchronization.shouldSync = shouldSync
  },

  setSyncStepsCount (state, count) {
    state.stepsCount = count
  },

  setSyncStepsFinished (state, count) {
    state.stepsFinished = count
  }
}
