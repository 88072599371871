export const state = () => ({
  appState: {
    showSyncWrapper: true,
    shouldLogin: false,
    ready: false,
    busy: false,
    timerOn: false,
    storageOn: false,
    isAuthenticated: false,
    isConnected: false,
    shouldSync: false,
    canMergePayloads: false,
    batteryHigh: false,
    shouldWait: false,
    wait: false,
    fresh: false,
    checkingShouldSync: false,
    syncInitialized: false,
    mustConnect: false,
    mustCharge: false,
    synchronizing: false,
    synchronized: false,
    isMobile: false,
    canTouch: false,
    backgroundable: false,
    backgrounded: false,
    canDeauthenticate: false,
    mustSync: false,
    hasCompetitions: false,
    checkingSync: false,
    mustCheckSync: false,
    hasApp: false,
    authenticating: false
  },
  abs: {
    verified: {},
    component: '',
    lastUsed: false,
    deauthenticationToken: ''
  }
})

export const mutations = {
  setAppState (state, appState) {
    // state.appState = appState
    Object.keys(appState).forEach((key) => {
      if (typeof (appState[key]) !== 'boolean' || key.substr(0, 3) !== 'abs') {
        key = key.substr(3, 1).toLowerCase() + key.substr(4)

        state.abs[key] = appState[key]
      } else {
        state.appState[key] = appState[key]
      }
    })
  },

  updateAppState (state, changes) {
    const currentState = state.appState
    const absState = state.abs

    changes.forEach((change) => {
      const value = change.value
      const isAbs = typeof (change.value) !== 'boolean' || change.key.substr(0, 3) === 'abs'

      let key = change.key

      if (isAbs && key.substr(0, 3) === 'abs') {
        key = key.substr(3, 1).toLowerCase() + key.substr(4)
      }

      Object.defineProperty(!isAbs ? currentState : absState, key, {
        value,
        enumerable: true,
        writable: true
      })
    })

    state.appState = currentState
    state.abs = absState
  },

  setAppStateField (state, { key, value }) {
    const isAbs = typeof (value) !== 'boolean' || key.substr(0, 3) === 'abs'

    if (isAbs) {
      if (key.substr(0, 3) === 'abs') {
        key = key.substr(3, 1).toLowerCase() + key.substr(4)
      }

      state.abs[key] = value
    } else {
      state.appState[key] = value
    }
  }
}
