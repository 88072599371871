import platformFlow from './flows/platform-flow'
import appFlow from './flows/app-flow'

const shortid = require('shortid')

shortid.characters('0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-:')

class NoseworkInit {
  constructor (context) {
    this.context = context
    this.sessionId = shortid.generate()
  }

  async init () {
    await this.startInit()
    await platformFlow(this.context).init()

    console.log('platform flow ok')

    await appFlow(this.context).init()

    console.log('app flow ok')

    await this.endInit()

    console.log('end init ok')

    this.context.state().initialized = true
  }

  async startInit () {
    return await new Promise((resolve) => {
      setTimeout(() => {
        resolve(true)
      }, 100)
    })
  }

  async auth () {
    this.context.on('authenticated', true, () => {
      // @todo FLOWS: check for the sync decision

      this.context.once('deauthenticated', true, () => {
        // @todo FLOWS: make the app fresh
      })
    })

    await this.context.auth().authenticate({}, true)

    this.context.state().hasUserData = true

    return true
  }

  async endInit () {
    this.context.state().ready = true

    return await new Promise((resolve) => {
      setTimeout(() => {
        this.context.state().initialized = true

        resolve(true)
      }, 100)
    })
  }
}

export {
  NoseworkInit
}
