import { NoseworkPlatform } from './platform'

class NoseworkWeb extends NoseworkPlatform {
  constructor (ctx) {
    super(ctx)

    this.initOperations = [
      'network',
      'watchForAppStateChange'
    ]

    this.context.logger().info('web init')

    this.context.state().absBatteryLevel = 1
    this.context.state().isCharging = true
    this.context.state().absForceEnergyMode = 'high'
  }

  async watchForAppStateChange () {
    return await new Promise((resolve) => {
      this.context.on('appChange', (data) => {
        // @todo Store in storage
        this.context.state().absGracefulFinish = Date.now()
      })

      resolve(true)
    })
  }
}

export default ctx => new NoseworkWeb(ctx)
